import { toast, Zoom } from 'react-toastify';
import { env, ENVIRONMENT } from '../../config';
import AddToCartToast from '../../components/toasts/AddToCartToast';

const defaultOptions = {
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    className: 'toast-container',
    closeButton: false,
    rtl: false,
    bodyClassName: 'transition-group-container',
    transition: Zoom,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
}


const customIconDefaultOptions = {
    autoClose: 2000,
    type: toast.TYPE.INFO,
    hideProgressBar: true,
    position: "bottom-center",
    transition: Zoom,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    rtl: false,
    icon: false,
}


export const toastTypes = {
    success: 'success',
    error: 'error',
    info: 'info',
    warn: 'warn',
    addToCart: 'addToCart',
    default : 'default'
}


// showTast takes 4 arguments. Message to be shown, type of toast, environment in which it has to shown and options options 
export const showToast = (message, type, environment = env.PROD, options = {}, props = {}) => {

    // let show = environment === env.PROD ? true : process.env.NODE_ENV === environment
    let show = environment === env.PROD ? true : ENVIRONMENT === environment

    if (!show) {
        console.log(message);
        return
    }

    if (Object.keys(options).length == 0) {
        options = defaultOptions;
    }

    switch (type) {
        case toastTypes.success:
            toast.success(message, options);
            break;

        case toastTypes.error:
            toast.error(message, options);
            break;

        case toastTypes.info:
            toast.info(message, options);
            break;

        case toastTypes.warn:
            toast.warn(message, options);
            break;

        case toastTypes.addToCart:
            // console.log("123123rohit", props)
            toast(<AddToCartToast product={props} />, customIconDefaultOptions);
            break;

        default:
            console.log(typeof type);
            toast(message, options);
            break;
    }
}

export const testToast = (message, type, options = {}) => {

    let show = ENVIRONMENT === env.DEV
    if (!show) {
        console.log(message);
        return
    }

    if (Object.keys(options).length == 0) {
        options = defaultOptions;
    }

    switch (type) {
        case toastTypes.success:
            toast.success(message, options);
            break;

        case toastTypes.error:
            toast.error(message, options);
            break;

        case toastTypes.info:
            toast.info(message, options);
            break;

        case toastTypes.warn:
            toast.warn(message, options);
            break;

        default:
            console.log(typeof type);
            toast(message, options);
            break;
    }
}