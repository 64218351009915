/* eslint-disable @next/next/no-img-element */
import AOS from "aos";
import "aos/dist/aos.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { useRouter } from "next/router.js";
import React, { Fragment, useEffect } from "react";
import { Provider } from "react-redux";
import "react-step-progress-bar/styles.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import ErrorBoundary from '../components/ErrorBoundary';
import useScrollRestoration from "../components/UseScrollRestoration.js";
import { inviteFriendsPackageNames } from "../config/index.js";
import { jsInterfaceDataPoints } from '../config/js-interface-data-points.js';
import { setClientData, setIsDeviceRegistered } from "../store/slices/user";
import { store } from "../store/store";
import "../styles/add-to-cart-button.scss";
import '../styles/address.scss';
import "../styles/bobble-coins.scss";
import "../styles/bottom-fixed-bar.scss";
import "../styles/cart.scss";
import "../styles/carts.scss";
import "../styles/categories-page.scss";
import "../styles/category-banners-carousel.scss";
import "../styles/category-featured-products.scss";
import "../styles/category-product-listing-page.scss";
import "../styles/category.scss";
import '../styles/footer.scss';
import '../styles/globals.scss';
import "../styles/header.scss";
import "../styles/home.scss";
import "../styles/horizontal-carousel-offer.scss";
import '../styles/login.scss';
import "../styles/offers-header.scss";
import "../styles/order-details.scss";
import "../styles/order-items.scss";
import "../styles/order-placement.scss";
import '../styles/orders.scss';
import '../styles/product-details-page.scss';
import "../styles/product-not-found.scss";
import "../styles/product-quick-preview-card.scss";
import "../styles/product-quick-preview-continer.scss";
import "../styles/products-header.scss";
import '../styles/range_slider.scss';
import '../styles/referral.scss';
import "../styles/request-more-products.scss";
import "../styles/returns-and-replacement-page.scss";
import '../styles/reward-system-header.scss';
import '../styles/reward-system-home.scss';
import '../styles/reward-system-redeem-coins.scss';
import '../styles/reward-system-transactions.scss';
import "../styles/search-bar.scss";
import '../styles/separatoror.scss';
import "../styles/services.scss";
import "../styles/sort-and-filter.scss";
import "../styles/spinner-loader.scss";
import "../styles/splash-screen.scss";
import "../styles/step-by-step-progress.scss";
import "../styles/sub-category.scss";
import "../styles/toasts.scss";
import '../styles/top-deals-section.scss';
import "../styles/trending-searches-and-history.scss";
import "../styles/user-location.scss";
import '../styles/user-profile.scss';
import "../styles/wishlist.scss";
import "../styles/cashkaro.scss";
import "../styles/boucing-dots-loader.scss";
import { getTrendingSearch } from "../util/common/index.js";
import {
  getInstalledPackages,
  getIntentFilters,
  getUserMetaData,
  getUserLocationV2,
  getUserLocationV1,
  isPackagesInstalled,
  getUserMetaDataForRewards
} from '../util/jsInterfaces/index';
import { retrieveFromSessionStorage, storeInSessionStorage } from "../util/storageUtils/sessionStorageUtils";
const productionMode = false;
import Head from "next/head.js";
import ReactGA from "react-ga4";

function MyApp({ Component, pageProps }) {


  ReactGA.initialize("G-2LZJ40HJHH");
  ReactGA.send("pageview");

  let persistor = persistStore(store);
  const router = useRouter()
  useScrollRestoration(router)
  // calling interface methods to call for data from android client
  useEffect(() => {
    console.log("_app.js useEffect called");
    // TODO: remove this after testing
    // invokeTestEvent();
    if (retrieveFromSessionStorage("userMetDataFromClient") === null) {
      if ((router?.pathname?.includes("/rewards"))) {
        getUserMetaDataForRewards();
      } else {
        getUserMetaData();
        // Uncomment below to enable location prefetch
        // try {
        //   getUserLocationV2(false)
        // }
        // catch (err) {
        //   //For handling getUserLocation for the old apk version
        // }
      }
      getIntentFilters();
      getInstalledPackages()
      getTrendingSearch();
      storeInSessionStorage("userMetDataFromClient", "true");
    }
    // Check installed apps for whatsapp, facebook, instagram
    isPackagesInstalled(inviteFriendsPackageNames);

    // getInstalledAppsCallback()

  }, []);

  // Control the splash screen
  // IN CASE Splash screen is required, uncomment the below code
  // useEffect(() => {
  //   if (!(router?.pathname?.includes("/home"))) {
  //     storeInSessionStorage("isSplashScreenDisplayedOnce", "true");
  //   }
  // }, [router])


  useEffect(() => {
    if (!(router?.pathname?.includes("/home"))) {
      storeInSessionStorage("isHomeScreenLoadedOnce", "true");
    }
  }, [router])

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });

    if (productionMode) {
      console.log = function () {        //This is to delete all console logs in production mode
      };
    } else {
      console.log("Developer mode");
    }
  });


  useEffect(() => {
    for (const dataPoint of jsInterfaceDataPoints) {
      window[dataPoint.id] = new Event(dataPoint.id);
      window.addEventListener(dataPoint.id, dataPoint.callBackMethod, false);
    }

    let metaData = {
      userId: 24,
      accessToken: "ZFb3w6VyTRPyOlg9wiaWEiclZO61iNV46dqbgWrJ",
      refreshToken: "C7f3XN6Kl15JdgfCB45QrDe3Q2ayUPKYZe1SALvQ",
      tokenType: "Bearer",
      expiresIn: "1209600",
      clientId: "7wZFJWA5chjgat68y826IAIKQ6s197RM",
      clientSecret: "CpesP92j0994TaLBTT9KFjP3RgZD7err",
      deviceId: "e53335718cdb06b8",
      deviceType: "android",
      phoneNumber: "8409387098",
      sdkVersion: "6",
      // LOCAL SETUP: change app version to 1 or any other version like 999999999 or 629000001 etc
      appVersion: 0,
      countryCode: "",
      dpandaAuthtoken: "",
      screenName: "app_super_app"
    }
    // store.dispatch(setUserAuthData({
    //   accessToken: metaData?.accessToken,
    //   refreshToken: metaData?.refreshToken,
    //   tokenType: metaData?.tokenType,
    //   expiresIn: metaData?.expiresIn,
    //   phoneNumber: metaData?.phoneNumber,
    // }));

    // LOCAL SETUP: Uncomment this block of code
    // store.dispatch(setClientData({
    //   clientId: metaData?.clientId,
    //   clientSecret: metaData?.clientSecret,
    //   deviceId: metaData?.deviceId,
    //   deviceType: metaData?.deviceType,
    //   sdkVersion: metaData?.sdkVersion,
    //   appVersion: metaData?.appVersion,
    //   screenName : metaData.screenName
    // }))

    // LOCAL SETUP: Uncomment this block of code
    // store.dispatch(setIsDeviceRegistered(true))

    // store.dispatch(setUserData({
    //   userId: metaData?.userId,
    //   phoneNumber: metaData?.phoneNumber,
    // }));

    // initiateDataSync(metaData)
  }, []);

  return (
    <Fragment>
      {
        jsInterfaceDataPoints.map((dataPoint, index) => {
          return (
            <input id={dataPoint.id} type="hidden" className="d-none" key={index} />
          )
        })
      }
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
      </Head>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
      <ToastContainer limit={1} />
    </Fragment>
  );
}

export default MyApp
