import {CDN_ASSETS_URL} from "./index.js"

const cdnHost = CDN_ASSETS_URL+"/super-apps/webview-icons/"


export const noProductImagePlaceholder = cdnHost + 'no_image_placeholder.webp';
export const noProductsToShow = cdnHost + "no_products_to_show.webp";
export const unexpectedErrorAsset = cdnHost + 'unexpected_error_asset_2x.webp';
export const emptyCartAsset = cdnHost + 'empty_cart_asset_2x.webp';
export const emptyWishlistAsset = cdnHost + 'empty_wishlist_asset_2x.webp';
