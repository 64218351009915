import { host } from "./index"
import axios from 'axios';
import { store } from "../../store/store"
import { regions } from "../../config/regions";

// saveUserAddress is used to save user address
export let saveUserAddress = async ({ accessToken, address, deviceId }) => {
    console.log("saveUserAddress", accessToken, address)
    let url = `${host}/v1/superApps/users/address?deviceId=${deviceId}`;

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify(address),
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)
};

// getUserAddress is used to get user address
export let getUserAddresses = async (payload) => {
    let accessToken = payload.accessToken;
    let deviceId = payload.deviceId;
    let url = `${host}/v1/superApps/users/address?deviceId=${deviceId}`;

    let request = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)
};

// updateUserAddress is used to update user address
export let updateUserAddress = async (accessToken, addressId, addressDetails, deviceId) => {

    let url = `${host}/v1/superApps/users/${addressId}/address?deviceId=${deviceId}`;

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify(addressDetails),
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)
};

// getUserAddress is used to get user address
export let getUserAddressesWithRetry = async (payload) => {
    let accessToken = payload.accessToken;
    let url = `${host}/v1/superApps/users/address`;

    let request = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)

};

//sending address details to dpanda
export let updateUserAddressOnDpanda = async (address, deviceId) => {
    let state = await store.getState();
    let dpandaAuthtoken = state?.user?.data?.dpandaAuthtoken;
    let accessToken = state?.user?.authData?.accessToken;
    let phoneNumber = state?.user?.authData?.phoneNumber;

    // Added check if user is logout, don't send phone number 
    if (!accessToken || accessToken === "") {
        phoneNumber = ""
    }
    
    let statesArray = regions;
    let stateCode, countryCode;
    if (statesArray) {
        statesArray.map((s) => {
            if ((s.name.toString().toLowerCase() === address.state.toString().toLowerCase()) ||
                (s.iso_code.toString().toLowerCase() === address.state.toString().toLowerCase())
            ) {
                stateCode = s.iso_code;
                countryCode = s.country_code;
            }
        })
    }
    let name = address.name.split(" ");
    let firstName = name[0];
    let lastName = "";
    if (name?.length > 1) {
        name.map((item, index) => {
            if (index > 0) {
                if (lastName == "") {
                    lastName = lastName.concat(item);
                }
                else {
                    lastName = lastName.concat(" ", item);
                }
            }
        })
    }
    let request = {
        method: 'POST',
        url: `${host}/v1/superApps/users/updateCheckoutAddress`,
        headers: {
            'Authorization': accessToken,
            "dpanda-access-token": dpandaAuthtoken,
            'Content-Type': 'application/json'
        },
        data: {
            "customerPhone": phoneNumber,
            "firstName": firstName,
            "lastName": lastName,
            "mobileNumber": address.phoneNumber,
            "state": stateCode,
            "city": address.city,
            "pincode": address.pincode,
            "addressOne": address.address,
            "addressTwo": address.landmark,
            "countryCode": countryCode,
            "isDefault": "1",
            "deviceId": deviceId
        }
    };
    return axios(request)

}
