import { createSlice } from '@reduxjs/toolkit';

export const homePageHeaderState = {
    searchString: '',
    showSearchBar: false,
    showTrendingSearchesAndHistory: false,
    showBobbleSuperLogo: true,
    showUserWishList: false,
    showUserCart: false,
    showUserLocation: true,
    addLineBreak: false,
    showPoweredBy: false,
    showBackButton: false,
    showHeaderText: false,
    headerText: "",
    poweredByContent: "",
    showMyOrdersButton: false,
    closeTrendingSearchesAndHistory: false,
    showSearchBarIcon: false,
    searchBarPlaceHolder: "Search for snacks, earphones",
    showCategoriesContainer: false,
    showUserProfileIcon: true
}

export const productDetailsHeaderState = {
    searchString: '',
    showSearchBar: false,
    showTrendingSearchesAndHistory: false,
    showBobbleSuperLogo: false,
    showUserWishList: false,
    showUserCart: false,
    showUserLocation: false,
    addLineBreak: false,
    showPoweredBy: false,
    showBackButton: true,
    showHeaderText: true,
    headerText: "",
    poweredByContent: "",
    showMyOrdersButton: false,
    closeTrendingSearchesAndHistory: false,
    showSearchBarIcon: false,
    searchBarPlaceHolder: "Search for snacks, earphones",
    showCategoriesContainer: false,
    showUserProfileIcon: false,
}

export const searchBarHeaderState = {
    searchString: '',
    showSearchBar: false,
    showTrendingSearchesAndHistory: false,
    showBobbleSuperLogo: false,
    showUserWishList: false,
    showUserCart: false,
    showUserLocation: false,
    addLineBreak: false,
    showPoweredBy: false,
    showBackButton: false,
    showHeaderText: false,
    headerText: "",
    poweredByContent: "",
    showMyOrdersButton: false,
    closeTrendingSearchesAndHistory: false,
    showSearchBarIcon: false,
    searchBarPlaceHolder: "Search for snacks, earphones",
    showCategoriesContainer: false,
    showUserProfileIcon: false
}


const headerSlice = createSlice({
    name: 'header',
    initialState: {
        searchString: '',
        showSearchBar: false,
        showBobbleSuperLogo: true,
        showUserWishList: false,
        showUserCart: false,
        showUserLocation: true,
        addLineBreak: true,
        showPoweredBy: false,
        showBackButton: false,
        showHeaderText: false,
        headerText: "",
        poweredByContent: "",
        showMyOrdersButton: false,
        showTrendingSearchesAndHistory: false,
        closeTrendingSearchesAndHistory: true,
        showSearchBarIcon: true,
        searchBarPlaceHolder: "Search for snacks, earphones",
        showClearFiltersButton: false,
        showSavedAddressesButton: false,
        showCategoriesContainer: false,
        showUserProfileIcon: false,
    },
    reducers: {
        setSearchString: (state, action) => {
            state.searchString = action.payload;
        },
        setUserProfileState: (state, action) => {
            state.headerText = "My Account"
            state.showBackButton = true
            state.showHeaderText = true
            state.addLineBreak = true
        },
        setShowSearchBar: (state, action) => {
            state.showSearchBar = action.payload;
        },
        setShowTrendingSearchesAndHistory: (state, action) => {
            state.showTrendingSearchesAndHistory = action.payload;
            if (action.payload) {
                state.addLineBreak = false;
            }
        },
        setShowUserWishList: (state, action) => {
            state.showUserWishList = action.payload;
        },
        setShowUserCart: (state, action) => {
            state.showUserCart = action.payload;
        },
        setShowUserLocation: (state, action) => {
            state.showUserLocation = action.payload;
        },
        setAddLineBreak: (state, action) => {
            state.addLineBreak = action.payload;
        },
        setShowPoweredBy: (state, action) => {
            state.showPoweredBy = action.payload;
        },
        setShowBackButton: (state, action) => {
            state.showBackButton = action.payload;
        },
        setShowHeaderText: (state, action) => {
            state.showHeaderText = action.payload;
        },
        setHeaderText: (state, action) => {
            state.headerText = action.payload;
        },
        setPoweredByContent: (state, action) => {
            state.poweredByContent = action.payload;
        },
        setShowMyOrdersButton: (state, action) => {
            state.showMyOrdersButton = action.payload;
        },
        setSearchBarPlaceHolder: (state, action) => {
            state.searchBarPlaceHolder = action.payload;
        },
        setCloseTrendingSearchesAndHistory: (state, action) => {
            state.closeTrendingSearchesAndHistory = action.payload;
        },
        setShowSearchBarIcon: (state, action) => {
            state.showSearchBarIcon = action.payload;
        },
        setShowUserProfileIcon: (state, action) => {
            state.showUserProfileIcon = action.payload
        },
        setShowcategoriesContainer: (state, action) => {
            state.showCategoriesContainer = action.payload
        },
        setShowClearFiltersButton: (state, action) => {
            state.showClearFiltersButton = action.payload;
        },
        setHomePageHeaderState: (state, action) => {
            state.showBobbleSuperLogo = true
            state.showUserLocation = true
            state.showSearchBarIcon = false
            state.showUserProfileIcon = true
        },
        setProductDetailsHeaderState: (state, action) => {
            state.showHeaderText = true
            state.showBackButton = true
            state.showUserCart = true
            state.showSearchBarIcon = true
            state.showUserWishList = true
            state.showHeaderText = false
        },
        setProductListingHeaderState: (state, action) => {
            state.showBackButton = true
            state.showHeaderText = true
            state.showSearchBarIcon = true
            state.showUserProfileIcon = false
        },
        setSearchBarHeaderState: (state, action) => {
            state.searchBarPlaceHolder = "Search for snacks, earphones"
            state.showBackButton = true
            state.showSearchBar = true
        },
        setAddressPageHeaderState: (state, action) => {
            state.headerText = ""
            state.showHeaderText = true
            state.showBackButton = true
            state.showSearchBarIcon = false
            state.addLineBreak = true
            state.showUserProfileIcon = false
        },
        resetHeaderState: (state, action) => {
            // state.searchString = ''
            state.showSearchBar = false
            state.showBobbleSuperLogo = false
            state.showTrendingSearchesAndHistory = false
            state.showUserWishList = false
            state.showUserCart = false
            state.showUserLocation = false
            state.addLineBreak = false
            state.showPoweredBy = false
            state.showBackButton = false
            state.showHeaderText = false
            state.headerText = ""
            //state.poweredByContent = ""
            state.showMyOrdersButton = false
            state.searchBarPlaceHolder = false
            state.closeTrendingSearchesAndHistory = false
            state.showSearchBarIcon = false
            state.showUserProfileIcon = false
            state.searchBarPlaceHolder = "Search for snacks, earphones"
            state.showSavedAddressesButton = false
            state.showClearFiltersButton = false
            state.showCategoriesContainer = false
            state.showUserProfileIcon = false
        },
        setCartHeaderState: (state, action) => {
            state.showBackButton = true
            state.showMyOrdersButton = true
            state.showHeaderText = true
            state.headerText = "My Cart"
            state.addLineBreak = true
        },
        setWishListHeaderState: (state, action) => {
            state.showBackButton = true
            state.showUserCart = true
            state.showHeaderText = true
            state.addLineBreak = true
            state.showSearchBarIcon = true
            state.headerText = "Wishlist"
            state.showUserProfileIcon = false
        },
        setRequestMoreHeaderState: (state, action) => {
            state.showBackButton = true
            state.showHeaderText = true
            state.addLineBreak = true
            state.headerText = "Request Products"
        },
        setMyOrdersHeaderState: (state, action) => {
            state.showBackButton = true
            state.showMyOrdersButton = false
            state.showHeaderText = true
            state.headerText = "My Orders"
            state.addLineBreak = true
        },
        setCategoriesPageHeaderState: (state, action) => {
            state.showUserWishList = true
            state.showUserCart = true
            state.showBackButton = true
            state.showHeaderText = true
            state.headerText = "All Categories"
            state.showSearchBarIcon = true
            state.addLineBreak = true
            state.showUserProfileIcon = false
        },
        setOrderDetailsHeaderState: (state, action) => {
            state.showBackButton = true
            state.showHeaderText = true
            state.headerText = "My Orders"
            state.addLineBreak = true
            state.showPoweredBy = true
        },
        setLocationPageHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "Deliver To"
            state.showHeaderText = true
            state.addLineBreak = true
        },
        setOrderPlacementHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "Order Status"
            state.addLineBreak = true
            state.showHeaderText = true
        },
        setCartDetailsPageHeaderState: (state, action) => {
            state.showBackButton = true
            state.showHeaderText = true
            state.headerText = "My Cart"
            state.addLineBreak = true
            state.showHeaderText = true
            state.showMyOrdersButton = true
        },
        setSortAndFilterHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "Sort and Filter"
            state.showHeaderText = true
            state.addLineBreak = true
            state.showClearFiltersButton = true
        },
        setLoginPageHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "Login"
            state.showHeaderText = true
            state.addLineBreak = true
        },
        setRewardsSystemLoginPageHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "Login"
            state.showHeaderText = true
            state.addLineBreak = true
        },
        setReturnAndReplacementPageHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "Returns/Replacements"
            state.showHeaderText = true
            state.addLineBreak = true
        },
        setShowSavedAddressButton: (state, action) => {
            state.showSavedAddressesButton = action.payload
        },
        setTopDealsHeaderState: (state, action) => {
            state.showBackButton = true
            state.headerText = "SALE"
            state.showHeaderText = true
            state.showSearchBarIcon = true
            state.showUserProfileIcon = false
        },
    },
});

export const {
    setSearchString, setShowSearchBar, setShowTrendingSearchesAndHistory,
    setShowUserWishList, setShowUserCart, setShowUserLocation, setAddLineBreak,
    setShowPoweredBy, setShowBackButton, setShowHeaderText, setHeaderText,
    setPoweredByContent, setShowMyOrdersButton, setSearchBarPlaceHolder, setCloseTrendingSearchesAndHistory,
    setShowSearchBarIcon, setShowUserProfileIcon, setHomePageHeaderState, setProductDetailsHeaderState, setSearchBarHeaderState,
    setAddressPageHeaderState, resetHeaderState, setHeaderState, setProductListingHeaderState, setCartHeaderState,
    setWishListHeaderState, setRequestMoreHeaderState, setMyOrdersHeaderState, setCategoriesPageHeaderState,
    setOrderDetailsHeaderState, setLocationPageHeaderState, setOrderPlacementHeaderState,
    setCartDetailsPageHeaderState, setShowClearFiltersButton, setSortAndFilterHeaderState,
    setLoginPageHeaderState, setReturnAndReplacementPageHeaderState, setShowSavedAddressButton, setShowcategoriesContainer, setUserProfileState, setTopDealsHeaderState, setRewardsSystemLoginPageHeaderState
} = headerSlice.actions;

export default headerSlice.reducer;
