import {
    invokeTruecallerLoginCallback,
    getUserMetaDataCallback,
    getInstalledAppsCallback,
    getUserAuthTokensCallback,
    getUserLocationCallback,
    getIntentFiltersCallback,
    shareProductCallback,
    superAppCallback,
    getEncryptedUserMetadataCallback,
    getEncryptedRegisterTasksCallback,
    getEncryptedRedeemRewardsCallback,
    invokeTruecallerLoginRewardSystemCallback,
    isPackagesInstalledCallback,
    invokeLoginActivityCallback, refreshRewardsCallback, getUserMetaDataForRewardsCallback
} from '../util/jsInterfaces/callbacks'


export const authTokenDataPoint = "authTokens";
export const userMetadataDataPoint = "userMetadata";
export const userMetadataForRewardsDataPoint = "userMetadataForRewards";
export const trueCallerLoginResponseDataPoint = "trueCallerLoginResponse";
export const truecallerLoginRewardSystemResponseDataPoint = "truecallerLoginRewardSystemResponse";
export const userLocationDataPoint = "userLocation";
export const intentFiltersDataPoint = "intentFilters";
export const installedAppsDataPoint = "installedApps";
export const shareProductDataPoint = "shareProduct";
export const superAppDataPoint = "superApp";
export const refreshRewardsDataPoint = "refreshRewardsHome";
export const encryptedUserMetadataDataPoint = "encryptedUserMetadataData";
export const encryptedRegisterTasksDataPoint = "encryptedRegisterTasksData";
export const encryptedRedeemRewardsDataPoint = "encryptedRedeemRewardsData";
export const loginRewardSystemResponseDataPoint = "loginRewardSystemResponse";
export const isPackagesInstalledDataPoint = "isPackagesInstalled";

const jsInterfaceDataPoints = [
    {
        id: encryptedUserMetadataDataPoint,
        callBackMethod: getEncryptedUserMetadataCallback
    },
    {
        id: encryptedRegisterTasksDataPoint,
        callBackMethod: getEncryptedRegisterTasksCallback
    },
    {
        id: encryptedRedeemRewardsDataPoint,
        callBackMethod: getEncryptedRedeemRewardsCallback
    },
    {
        id: authTokenDataPoint,
        callBackMethod: getUserAuthTokensCallback
    },
    {
        id: userMetadataDataPoint,
        callBackMethod: getUserMetaDataCallback
    },
    {
        id: userMetadataForRewardsDataPoint,
        callBackMethod: getUserMetaDataForRewardsCallback
    },
    {
        id: trueCallerLoginResponseDataPoint,
        callBackMethod: invokeTruecallerLoginCallback
    },
    {
        id: truecallerLoginRewardSystemResponseDataPoint,
        callBackMethod: invokeTruecallerLoginRewardSystemCallback
    },
    {
        id: userLocationDataPoint,
        callBackMethod: getUserLocationCallback
    },
    {
        id: intentFiltersDataPoint,
        callBackMethod: getIntentFiltersCallback
    },
    {
        id: installedAppsDataPoint,
        callBackMethod: getInstalledAppsCallback
    },
    {
        id: shareProductDataPoint,
        callBackMethod: shareProductCallback
    }, {
        id: superAppDataPoint,
        callBackMethod: superAppCallback
    }, {
        id: loginRewardSystemResponseDataPoint,
        callBackMethod: invokeLoginActivityCallback
    }, {
        id: isPackagesInstalledDataPoint,
        callBackMethod: isPackagesInstalledCallback
    }, {
        id: refreshRewardsDataPoint,
        callBackMethod: refreshRewardsCallback
    }
]

export { jsInterfaceDataPoints };
