import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig()

export const DPANDA_HOST = publicRuntimeConfig.NEXT_PUBLIC_DPANDA_HOST || "https://app.dpanda.in";

export const DPANDA_LOGIN_ENDPOINT = `/index.php?route=api/login`

export const DPANDA_CART_ENDPOINT = `/index.php?route=checkout/cart`

export const DPANDA_PUB_ID = "37"


export let DpandaPendingStatus = "Pending" // success
export let DpandaProcessedStatus = "Processed" // success
export let DpandaReadyForPickUpStatus = "Ready for Pick Up"
export let DpandaShippedStatus = "Shipped"
export let DpandaDeliveredStatus = "Delivered"
export let DpandaCancelledStatus = "Canceled"
export let DpandaOutForDeliveryStatus = "Out for Delivery"
export let DpandaRTOInitiatedStatus = "RTO Initiated"
export let DpandaRTODeliveredStatus = "RTO Delivered"
export let DpandaReturnInitiatedStatus = "Return Initiated"
export let DpandaReturnDeliveredStatus = "Return Delivered"
export let DpandaCompleteStatus = "Complete"
export let DpandaCODReceived = "COD Received"
export let DpandaPaid = "Paid" //
export let DpandaFulfilledStatus = "fulfilled"
export let DpandaProcessingStatus = "Processing"
export let DpandaFailedStatus = "Failed"
export let DpandaRefundedStatus = "Refunded"
export let DpandaReturnCompletedStatus = "Return completed"
