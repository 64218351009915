import { host } from "./index"
import axios from "axios";
import { store } from "../../store/store"

export const getUserCart = async ({ userId, accessToken, dpandaAuthtoken, vendorId = 1 }) => {
    let state = await store.getState();
    let deviceId = state?.user?.clientData?.deviceId;
    let request = {
        method: 'get',
        url: `${host}/v1/superApps/users/${userId}/carts?vendorId=${vendorId}&deviceId=${deviceId}`,
        headers: {
            'Authorization': accessToken,
            'dpanda-access-token': dpandaAuthtoken
        },
        body: JSON.stringify({ dpandaAuthtoken })
    };

    return axios(request)
}

export const addProductToCart = async ({ item }) => {
    let state = await store.getState();
    let dpandaAuthtoken = state?.user?.data?.dpandaAuthtoken;
    let userId = state?.user?.data?.userId;
    let accessToken = state?.user?.authData?.accessToken;
    let deviceId = state?.user?.clientData?.deviceId;
    console.log("Add to cart", { item, dpandaAuthtoken, userId, accessToken, deviceId })

    let request = {
        method: 'POST',
        url: `${host}/v1/superApps/users/${userId}/cart/increment?deviceId=${deviceId}`,
        headers: {
            'Authorization': accessToken,
            "dpanda-access-token": dpandaAuthtoken
        },
        data: {
            "productId": item?.data?.productId,
            "variantId": item?.variants?.[0]?.id,
            "vendorId": item?.vendor?.id,
            "incrementBy": item?.incrementBy ?? 1,
            "dpandaAuthtoken": dpandaAuthtoken
        }
    };
    return axios(request)
}

export const removeProductFromCart = async (userId, item, accessToken, dpandaAuthtoken, deviceId) => {
    let request = {
        method: 'post',
        url: `${host}/v1/superApps/users/${userId}/cart/decrement?deviceId=${deviceId}`,
        headers: {
            'Authorization': accessToken,
            "dpanda-access-token": dpandaAuthtoken
        },
        data: {
            "productId": item?.data?.productId,
            "variantId": item?.variants?.[0]?.id,
            "vendorId": item?.vendor?.id,
            "decrementBy": 1,
            "dpandaAuthtoken": dpandaAuthtoken
        }
    };

    return axios(request)
}

export const getProductsForUserCart = async (userId, accessToken, vendorId, deviceId) => {
    let request = {
        method: 'get',
        url: `${host}/v1/superApps/users/${userId}/carts/${vendorId}?deviceId=${deviceId}`,
        headers: {
            'Authorization': accessToken,
            "dpanda-access-token": dpandaAuthtoken
        }
    };

    return axios(request)
}