import axios from 'axios';
import { host } from "./index";

export let getDeliveryDetails = async (pincode) => {
    let url = `${host}/v1/superApps/categories`;

    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)
}

export let getProductDetails = async (productId, vendor, userId, deviceId) => {
    let url = `${host}/v2/superApps/products/${productId}?vendor=${vendor}`;
    if(userId != null){
        url = url + "&userId=" + userId
    }
    if(deviceId != null){
        url = url + "&deviceId=" + deviceId
    }
    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)
}

export let fetchAllProductsByCategory = async (category, page, limit, userId, deviceId) => {
    if (page === undefined || page === null || page === "") {
        page = 1;
    }
    if (limit === undefined || limit === null || limit === "") {
        limit = 20;
    }

    let url = `${host}/v2/superApps/products?`;
    let query = new URLSearchParams();
    query.append("category", category);
    query.append("page", page);
    query.append("limit", limit);
    if(userId != null){
        query.append("userId",userId);
    }
    if(deviceId != null){
        query.append("deviceId",deviceId)
    }

    url += query.toString();

    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)
}

export let fetchProducts = async (searchString, page, limit, userId, deviceId) => {

    if (page === undefined || page === null || page === "") {
        page = 1;
    }
    if (limit === undefined || limit === null || limit === "") {
        limit = 20;
    }

    let url = `${host}/v2/superApps/products?`;
    let query = new URLSearchParams();
    query.append("page", page);
    query.append("limit", limit);
    if(userId != null){
        query.append("userId", userId);
    }
    if(deviceId != null){
        query.append("deviceId",deviceId)
    }

    if (searchString && searchString.length > 0) {
        query.append("searchString", searchString);
    }

    url += query.toString();

    let request = {
        method: "GET",
        url: url,
    };

    return axios(request);
}

export let fetchProductsByFilter = async ({ filterBy, sortBy, minPrice, maxPrice, orderBy, searchString, page, limit, pageToken, intents, category, subCategory, brands, customTags, filterVendor, userId, deviceId }) => {

    if (page === undefined || page === null || page === "") {
        page = 1;
    }
    if (limit === undefined || limit === null || limit === "") {
        limit = 20;
    }

    let body;
    let url = `${host}/v2/superApps/products?`;

    console.log({ filterBy }, { sortBy }, { minPrice }, { maxPrice }, { orderBy }, { searchString }, { page }, { limit }, { intents }, { category }, { subCategory }, { brands }, { pageToken });

    let query = new URLSearchParams();

    query.append("page", page);
    query.append("limit", limit);
    if(userId != null){
        query.append("userId", userId);
    }
    if(deviceId != null){
        query.append("deviceId",deviceId)
    }
    if (pageToken?.length > 0) {
        query.append("pageToken", pageToken);
    }

    if (searchString && searchString?.length > 0) {
        query.append("searchString", searchString);
    }

    if (filterBy?.length > 0) {
        query.append("filterBy", filterBy);
    }

    if (maxPrice > 0 && minPrice < maxPrice) {
        query.append("min", minPrice);
        query.append("max", maxPrice);
    }

    if (sortBy?.length > 0) {
        query.append("sortBy", sortBy);
    }

    if (orderBy == "asc") {
        query.append("orderBy", "asc");
    } else if (orderBy == "desc") {
        query.append("orderBy", "desc");
    }

    if (category?.length > 0) {
        query.append("category", category);
    }

    if (subCategory?.length > 0) {
        query.append("subCategory", subCategory);
    }

    if (brands?.length > 0) {
        query.append("brands", brands);
    }

    if (customTags?.length > 0) {
        query.append("customTags", customTags);
    }

    if (filterVendor?.length > 0) {
        query.append("filterVendor", filterVendor);
    }

    url += query.toString();
    if (intents?.length > 0) {
        body = {
            intents: intents
        }
    }

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify(body)
    }

    return axios(request)
}

export let requestMoreProducts = async (productRequest) => {

    let url = `${host}/v1/superApps/products/request`;

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify(productRequest),
        headers: {
            "Content-Type": "application/json",
        }
    }

    return axios(request)
}

export let getTrendingSearchList = async () => {
    let url = `${host}/v1/superApps/search/trending/list`;

    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)
}

export let getTopDealProducts = async (userId, deviceId) => {
    let url = `${host}/v1/superApps/products/top/deals`;
    if(deviceId != null){
        url = url + "?deviceId=" + deviceId
    }
    if(userId != null && deviceId != null){
        url = url + "&userId=" + userId
    } else if(userId != null){
        url = url + "?userId=" + userId
    }

    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)
}