import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserCart } from '../../util/apiUtils/cart';
import { dispatchEventToNative, ActionsTypes, ServiceTypes, SuperAppErrorOccured } from '../../util/events';

export const fetchCarts = createAsyncThunk('cart/fetchCarts', async ({ userId, accessToken, dpandaAuthtoken, vendorId = 1 }) => {
    const carts = await getUserCart({ userId, accessToken, dpandaAuthtoken, vendorId });
    return carts?.data?.carts;
})

let cartScehma = {
    products: [],
    vendor: {
        id: '',
        name: '',
        icon: '',
        vendorId: '',
    },
    cartPrice: 0,
    cartDiscountedPrice: 0,
    cartItems: 0,

}

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        carts: [],
        items: [],
        totalPrice: 0,
        totalDiscountedPrice: 0,
        totalCartItems: 0,
        selectedCart: {},
        error: null,
        loading: false
    },
    reducers: {

        addToCart: (state, action) => {
            state.items = [...state.items, action.payload]
        },

        increaseQuantity: (state, action) => {
            state.items = state.items.map(item => {
                if (item.data.productId === action.payload?.data?.productId && item?.variants?.[0]?.id === action.payload?.variants?.[0]?.id) {
                    return { ...item, cartQuantity: item.cartQuantity + 1 };
                }
                return item;
            })

        },

        removeFromCart: (state, action) => {
            state.items = state.items.map(item => {
                if (item.data.productId === action.payload.data.productId && item?.variants?.[0]?.id === action.payload?.variants?.[0]?.id) {
                    return { ...item, cartQuantity: item.cartQuantity - 1 };
                }
                return item;

            }).filter(item => item?.cartQuantity > 0)
        },

        getCartTotal: (state, action) => {
            console.log('getCartTotal', state?.items)
            let reducerValue = state?.items.reduce((acc, item) => {
                let { price, discountedPrice } = item.variants?.[0];
                let { cartQuantity } = item
                acc.totalPrice += price * cartQuantity;
                acc.totalDiscountedPrice += discountedPrice * cartQuantity;
                acc.totalCartItems += cartQuantity;
                return acc;
            }, { totalPrice: 0, totalDiscountedPrice: 0, totalCartItems: 0 });

            state.totalPrice = reducerValue?.totalPrice;
            state.totalDiscountedPrice = reducerValue?.totalDiscountedPrice;
            state.totalCartItems = reducerValue?.totalCartItems;
        },

        emptyCart: (state, action) => {
            console.log('emptyCart was called', state)
            state.carts = [];
            state.items = [];
            state.totalPrice = 0;
            state.totalDiscountedPrice = 0;
            state.totalCartItems = 0;
        },

        getAllCarts: (state, action) => {
            console.log('getAllCarts was called', state)
            let consolidatedCart = []
            let cart = {
                products: [],
                vendor: {
                    id: '',
                    name: '',
                    icon: '',
                    vendorId: '',
                },
            }
            state.items.forEach(item => {
                consolidatedCart?.find(cart => cart.vendor.id === item.vendor.id) ? consolidatedCart.find(cart => cart.vendor.id === item.vendor.id).products.push(item) : consolidatedCart.push({ ...cart, vendor: item.vendor, products: [item] })
            })

            state.carts = consolidatedCart;
        },

        getSelectedCart: (state, action) => {
            console.log('getSelectedCart was called', action.payload)
            if (state.carts.length === 0) {
                state.selectedCart = {}
                return;
            }

            state.carts?.forEach(cart => {
                if (cart.vendor.id == action.payload) {
                    state.selectedCart = cart;
                }
            });

            if (state?.selectedCart?.products) {
                let { totalPrice, totalDiscountedPrice, totalCartItems } = state.selectedCart?.products?.reduce((acc, item) => {
                    let { price, discountedPrice } = item.variants?.[0];
                    let { cartQuantity } = item
                    acc.totalPrice += price * cartQuantity;
                    acc.totalDiscountedPrice += discountedPrice * cartQuantity;
                    acc.totalCartItems += cartQuantity;
                    return acc;
                }, { totalPrice: 0, totalDiscountedPrice: 0, totalCartItems: 0 });

                state.selectedCart.cartPrice = totalPrice;
                state.selectedCart.cartDiscountedPrice = totalDiscountedPrice;
                state.selectedCart.cartItems = totalCartItems;
            }
        },
        getSelectedCartTotal: (state, action) => {
            let { totalPrice, totalDiscountedPrice, totalCartItems } = state.selectedCart.products.reduce((acc, item) => {
                let { price, discountedPrice } = item.variants?.[0];
                let { cartQuantity } = item
                acc.totalPrice += price * cartQuantity;
                acc.totalDiscountedPrice += discountedPrice * cartQuantity;
                acc.totalCartItems += cartQuantity;
                return acc;
            }, { totalPrice: 0, totalDiscountedPrice: 0, totalCartItems: 0 });

            state.selectedCart.cartPrice = totalPrice;
            state.selectedCart.cartDiscountedPrice = totalDiscountedPrice;
            state.selectedCart.cartItems = totalCartItems;
        },
        setCartLoading: (state, action) => {
            state.loading = action.payload
        }
    },
    extraReducers: {
        [fetchCarts.fulfilled]: (state, action) => {
            console.log("New Cart From Server", action.payload)
            if (!action.payload || !Array.isArray(action.payload)) return;
            let newCarts = action.payload.map(cart => {
                let { products, vendor } = cart;
                let newProducts = products.map(product => {
                    let { variants } = product
                    return { ...product, cartQuantity: variants?.[0]?.cartQuantity ?? 1 }
                })
                return { products: newProducts, vendor }
            })

            let newItems = []
            state.carts = newCarts;
            state.items = []
            state.carts?.forEach(cart => {
                newItems = [... new Set([...state.items, ...cart.products])];
            })

            if (newItems) {
                state.items = newItems
            }

            state.error = null;
            state.loading = false;
        },
        [fetchCarts.rejected]: (state, action) => {
            state.error = action.error;
            state.loading = false;

            let eventLabel = {}
            eventLabel["service_type"] = ServiceTypes.productListing
            eventLabel["action_type"] = ActionsTypes.viewCart;
            eventLabel["error_message"] = action.error?.response?.data?.errorDescription
            eventLabel["error_code"] = action.error?.response?.data?.errorCode
            dispatchEventToNative(SuperAppErrorOccured, eventLabel);
        },
        [fetchCarts.pending]: (state, action) => {
            state.loading = true;
        }


    }
});

export const { addToCart, removeFromCart, increaseQuantity, getCartTotal, emptyCart, getAllCarts, getSelectedCart, getSelectedCartTotal, setCartLoading } = cartSlice.actions;

export default cartSlice.reducer;
