import { productSharingTemplate, ProductSharingDeeplink,CDN_ASSETS_URL } from "../../config";

import {
    authTokenDataPoint,
    intentFiltersDataPoint,
    trueCallerLoginResponseDataPoint,
    userLocationDataPoint,
    userMetadataDataPoint,
    installedAppsDataPoint,
    shareProductDataPoint,
    encryptedDataPoint,
    loginRewardSystemResponseDataPoint,
    isPackagesInstalledDataPoint, imageFromFiles, userMetadataForRewardsDataPoint,
} from '../../config/js-interface-data-points';
import { capitalizeFirstLetter } from "../common";
import { dispatchEventToNative, EventStatus, LocationRequestedEvent } from "../events";
import { setProductSharingState, setGetUserLocationFromClientOnGoing } from "../../store/slices/global";
import { showToast, testToast, toastTypes } from "../common/toasts";
import { store } from "../../store/store";

// invokeTruecallerLogin is used for sending invloking background flow for truecaller login
const invokeTruecallerLogin = () => {
    if (window && window.Android && window.Android.invokeTruecallerLogin) {
        window.Android.invokeTruecallerLogin(trueCallerLoginResponseDataPoint)
    }
}

// invokeTruecallerLoginForRewardSystem is used for sending invloking background flow for truecaller login
const invokeTruecallerLoginForRewardSystem = () => {
    if (window && window.Android && window.Android.invokeTruecallerLogin) {
        window.Android.invokeTruecallerLogin(trueCallerLoginResponseDataPoint)
    }
}

// invokeLoginActivity is used for sending invloking background flow for login
const invokeLoginActivity = () => {
    if (window && window.Android && window.Android.invokeLoginActivity) {
        window.Android.invokeLoginActivity(loginRewardSystemResponseDataPoint)
    }
}


// getUserMetaData is used for getting user metadata from native client
const getUserMetaData = () => {
    console.log("getUserMetaData-interface")
    if (window && window.Android && window.Android.sendUserMetaData) {
        window.Android.sendUserMetaData(userMetadataDataPoint)
    }
}

// getUserMetaDataForRewards is used for getting user metadata from native client
const getUserMetaDataForRewards = () => {
    console.log("getUserMetaData-interface")
    if (window && window.Android && window.Android.sendUserMetaData) {
        window.Android.sendUserMetaData(userMetadataForRewardsDataPoint)
    }
}

// getUserAuthTokens is used for getting user auth tokens from native client
const getUserAuthTokens = () => {

    if (window && window.Android && window.Android.getUserAuthTokens) {
        window.Android.getUserAuthTokens(authTokenDataPoint)
    }
}

// syncRewards is used for syncing rewards from native client
const syncRewards = () => {
    if (window && window.Android && window.Android.syncRewards) {
        window.Android.syncRewards()
    }
}


// getUserLocationV1 is used for getting user location from native client in this the location permission will popup(in android) in any case
const getUserLocationV1 = () => {
    const { dispatch } = store;
    console.log("getUserLocationV1 envoked from js interface")
    dispatch(setGetUserLocationFromClientOnGoing(true))
    setTimeout(() => { dispatch(setGetUserLocationFromClientOnGoing(false)) }, 5000)
    // showToast("getUserLocation envoked", toastTypes.info)
    let eventLabel = {}
    eventLabel["status"] = EventStatus.success
    dispatchEventToNative(LocationRequestedEvent, eventLabel)

    if (window && window.Android && window.Android.sendUserLocation) {
        window.Android.sendUserLocation(userLocationDataPoint)
    }
}

// getUserLocationV2 is used for getting user location from native client new version that is in this the location popup will be based on the 
// requiredPermission param if it is false then if the user given permission we will fetch from that true we will make a popup to get user location
const getUserLocationV2 = (requiredPermission) => {
    const { dispatch } = store;
    console.log("getUserLocationV2 envoked from js interface")
    dispatch(setGetUserLocationFromClientOnGoing(true))
    setTimeout(() => { dispatch(setGetUserLocationFromClientOnGoing(false)) }, 5000)
    // showToast("getUserLocation envoked", toastTypes.info)
    let eventLabel = {}
    eventLabel["status"] = EventStatus.success
    dispatchEventToNative(LocationRequestedEvent, eventLabel)

    if (window && window.Android && window.Android.sendUserLocation) {
        window.Android.sendUserLocation(userLocationDataPoint,requiredPermission)
    }
}

// sendAuthTokensToClient Send auth tokens to client
const sendAuthTokensToClient = (authTokens) => {
    console.log("sendAuthTokensToClient", JSON.stringify(authTokens))
    if (window && window.Android && window.Android.receiveAuthTokenData) {
        console.log("sendAuthTokensToClient, receiveAuthTokenData", JSON.stringify(authTokens))
        window.Android.receiveAuthTokenData(JSON.stringify(authTokens))
    }
}


// getIntentFilters is used for getting intent filters from native client
const getIntentFilters = () => {

    console.log("getIntentFilters")
    if (window && window.Android && window.Android.sendIntentFilters) {
        console.log("getIntentFiltersnative")
        window.Android.sendIntentFilters(intentFiltersDataPoint)
    }
}


// shareProduct is used for product sharing
const shareProduct = (product, variantId, isbannerShare = false) => {

    const state = store.getState();

    // Send product name, image url and product link
    console.log("shareProduct", product)
    let imageUrl = "";
    let imageExtension = "";
    let productDetails = "";
    if (product?.data?.images?.length > 0) {
        imageUrl = product?.data?.images?.[0]?.image;
        imageExtension = product?.data?.images?.[0]?.image.split("?")[0].split(".").pop();
    }
    let productSharingObj = {}
    if (isbannerShare) {
        // TODO: update there vendor and product URL and check for deeplink
        productDetails = `Hey, a surprise 😲 is waiting for you! Download Bobble AI Keyboard to unlock your holiday gift 🎁 \n https://makemybobble.in/bobble-super-holiday`

        productSharingObj["productDetails"] = productDetails;
        productSharingObj["imageUrl"] = CDN_ASSETS_URL+"/super-apps/webview-icons/christmas-gift-campaign-iphone-share-image.png";
        productSharingObj["imageExtension"] = "png";
    } else {
        // TODO: update there vendor and product URL and check for deeplink
        productDetails = productSharingTemplate.replace("PRODUCT_NAME", product?.data?.name).
            // removed VENDOR_NAME, we will be showing Bobble Xclusive.
            // replace("VENDOR_NAME", capitalizeFirstLetter(product?.vendor?.name)).
            // replace("PRODUCT_URL", `${ProductSharingDeeplink}?variantId=${variantId}&vendor=${product?.vendor?.name}&path=/bobblexclusive/products/${product?.data?.productId}&isShared=${true}`);
            replace("PRODUCT_URL", `${ProductSharingDeeplink}?product_id=${product?.data?.productId}`)

        productSharingObj["productDetails"] = productDetails;
        productSharingObj["imageUrl"] = imageUrl;
        productSharingObj["imageExtension"] = imageExtension;
    }

    productSharingObj = JSON.stringify(productSharingObj);

    console.log("productSharingObj", productSharingObj)
    if (window?.Android?.shareProduct && !state?.global?.isProductSharingActive) {
        console.log("productSharingObjNative", productSharingObj)
        store?.dispatch(setProductSharingState({ isProductSharingActive: true, sharedProductId: product?.data?.productId }));
        setTimeout(() => { store?.dispatch(setProductSharingState({ isProductSharingActive: false, sharedProductId: "" })) }, 5000)
        window.Android.shareProduct(shareProductDataPoint, productSharingObj)
    }
}

// onInputFocus send an event to client on input focus to open the KB
const onInputFocus = () => {

    console.log("inpout focus enter")
    if (window && window.Android) {
        console.log("inpout focus")
        window.Android.openKeyboard()
        console.log("input focus kb open")
    }
}


// downloadInvoice is used for downloading invoice
const downloadInvoice = (invoiceURL) => {

    if (window?.Android?.downloadInvoice) {
        window.Android.downloadInvoice(invoiceURL)
    }
}

// getInstalledPackages is used for getting installed packages from native client
const getInstalledPackages = () => {

    console.log("getInstalledPackages")
    if (window?.Android?.sendInstalledPackages) {
        console.log("getInstalledPackagesnative")
        window.Android.sendInstalledPackages(installedAppsDataPoint)
    }
}

// closeKB is used for closing the keyboard
export const closeKB = () => {

    if (window?.Android?.closeKeyboard) {
        window.Android.closeKeyboard()
    }
}


// stopProductSharing stops the product sharing
export const stopProductSharing = () => {

    if (window?.Android?.stopProductSharing) {
        window.Android.stopProductSharing()
    }
}

// TODO: update with client
export const invokeSuperApp = (superapp) => {
    console.log("invokeSuperApp called", JSON.stringify(superapp))
    if (window?.Android?.invokeSuperApp) {
        window.Android.invokeSuperApp(JSON.stringify(superapp))
    }
}

// invokeCreateTheme is used for redirecting to create theme page in client
export const invokeCreateTheme = () => {
    console.log("JS invokeCreateTheme called")
    if (window?.Android?.invokeCreateTheme) {
        window.Android.invokeCreateTheme()
    }
}

// invokeCreateHead is used for redirecting to create head page in client
export const invokeCreateHead = () => {
    console.log("JS invokeCreateHead called")
    if (window?.Android?.invokeCreateHead) {
        window.Android.invokeCreateHead()
    }
}

// invokeBubbly is used for redirecting to bubbly page in client
export const invokeBubbly = () => {
    console.log("JS invokeBubbly called")
    if (window?.Android?.invokeBubbly) {
        window.Android.invokeBubbly()
    }
}

// getEncryptedData is used for getting encrypted data from native client
export const getEncryptedData = (key, data) => {
    console.log("JS getEncryptedData called")
    if (window?.Android?.sendEncryptedBody) {
        window.Android.sendEncryptedBody(key, data)
    }
}

// sendTaskEventsList is used for sending task events list to native client
export const sendTaskEventsList = (taskEventsList) => {
    console.log("JS sendTaskEventsList called", taskEventsList)
    if (window?.Android?.sendTaskEventsList) {
        window.Android.sendTaskEventsList(taskEventsList)
    }
}

// sendUserCoins is used for sending user coins to native client
export const sendUserCoins = (coins) => {
    console.log("JS sendUserCoins called", coins)
    if (window?.Android?.sendUserCoins) {
        window.Android.sendUserCoins(coins)
    }
}

// backButtonClicked is used for sending back button click event to native client
export const backButtonClicked = () => {
    console.log("JS backButtonClicked called")
    if (window?.Android?.backButtonClicked) {
        window.Android.backButtonClicked()
    }
}

// sendInviteMessageToNative  is used for sending invite message to native client
export const sendInviteMessageToNative = (text, imageURLs, packageName) => {
    console.log("JS sendInviteMessageToNative called", text, imageURLs, packageName)
    if (window && window.Android && window.Android.sendInviteMessageToNative) {
        window.Android.sendInviteMessageToNative(text, packageName, JSON.stringify(imageURLs))
    }
}

// isPackagesInstalled is used for checking if package is installed or not in native client
export const isPackagesInstalled = (packageNames) => {
    console.log("isPackagesInstalled called")
    console.log("isPackagesInstalled called", packageNames)
    if (window?.Android?.isPackagesInstalled) {
        console.log("isPackagesInstalled")
        window.Android.isPackagesInstalled(isPackagesInstalledDataPoint, JSON.stringify(packageNames))
    }
}

export const getDeepLinkURIList = (urlList) => {
    console.log("openInBrowser called", urlList)
    if (window?.Android?.getDeepLinkURLList) {
        window.Android.getDeepLinkURIList(JSON.stringify(urlList))
    }
}


export {
    invokeTruecallerLogin,
    invokeTruecallerLoginForRewardSystem,
    getUserMetaData,
    getUserAuthTokens,
    sendAuthTokensToClient,
    onInputFocus,
    shareProduct,
    getUserLocationV2,
    getUserLocationV1,
    getIntentFilters,
    downloadInvoice,
    getInstalledPackages,
    invokeLoginActivity,
    getUserMetaDataForRewards,
    syncRewards
};

