import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAddresses } from '../../util/apiUtils/user-address';
import { SelectLocationHeaderText } from '../../config/userAddress'
import { fetchWithRetry } from '../../util/dataSync'


export const fetchAddresses = createAsyncThunk('userAddress/fetchAddresses', async ({accessToken, deviceId}) => {
    let payload = { accessToken, deviceId };
    console.log("fetchAddresses: ", payload);
    let res = await fetchWithRetry(getUserAddresses, payload);
    return res?.data?.userAddresses;
})

const userAddressSlice = createSlice({
    name: 'userAddress',
    initialState: {
        userAddresses: [],
        loading: false,
        error: null,
        idCounter: 0,
        defaultAddress: {},
        selectedAddress: {},
        selectedLocation: {
            pincode: '',
            city: '',
            state: '',
            landmark: '',
            address: '',
            addressType: '',
            addressName: '',
            isDefault: '',
            countryCode: '',
        },
        userLocationFromClient: {
            pincode: '',
            city: '',
            state: '',
            landmark: '',
            address: '',
            addressType: '',
            addressName: '',
            isDefault: '',
            countryCode: '',
        },
        userLocation: {},
        latLong: {
            latitude: '',
            longitude: ''
        },
        selectedLocationDisplayText: SelectLocationHeaderText,
        getUserLocationFromClientOnGoing: false
    },
    reducers: {
        setIDCounter: (state, action) => {
            state.idCounter = action.payload
        },

        addAddress: (state, action) => {
            state.userAddresses = [...state.userAddresses, action.payload];
        },

        editAddress: (state, action) => {
            state.userAddresses = state.userAddresses.map(userAddress => {
                let { addressId, address } = action.payload
                if (userAddress.id === addressId) {
                    return { ...address, id: addressId };
                }
                return userAddress;
            })
        },

        removeAddress: (state, action) => {
            state.userAddresses = state.userAddresses.filter(address => address.id !== action.payload.id);
        },

        changeDefaultAddress: (state, action) => {
            state.userAddresses = state.userAddresses.map(address => {
                if (address.id === action.payload.id) {
                    address.isDefault = true;
                } else {
                    address.isDefault = false;
                }
                return address;
            })
        },

        getSelectedLocationDisplayText: (state, action) => {
            let selectedLocationDisplayText = state.selectedLocation;

            if (selectedLocationDisplayText && selectedLocationDisplayText.address && selectedLocationDisplayText.city) {
                state.selectedLocationDisplayText = selectedLocationDisplayText.address + ", " + selectedLocationDisplayText.city;
            } else {
                state.selectedLocationDisplayText = SelectLocationHeaderText
            }
        },
        setSelectedAddress: (state, action) => {
            state.selectedAddress = action.payload;
            state.selectedLocation = action.payload;
            if (state?.selectedAddress?.address && state?.selectedAddress?.city) {
                state.selectedLocationDisplayText = state.selectedAddress.address + ", " + state.selectedAddress.city;
            } else {
                state.selectedLocationDisplayText = SelectLocationHeaderText
            }

        },
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action.payload;
            if (action.payload && action.payload?.address && action.payload?.city) {
                state.selectedLocationDisplayText = action.payload?.address + ", " + action.payload?.city;
            } else {
                state.selectedLocationDisplayText = SelectLocationHeaderText
            }
        },
        setLatLong: (state, action) => {
            state.latLong = action.payload;
        },
        setUerLocationFromClient: (state, action) => {
            state.userLocationFromClient = action.payload;
        },
        setGetUserLocationFromClientOnGoing: (state, action) => {
            state.getUserLocationFromClientOnGoing = action.payload;
        }, setUserLocation: (state, action) => {
            state.userLocation = action.payload;
        }
    },
    extraReducers: {
        [fetchAddresses.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchAddresses.fulfilled]: (state, action) => {
            state.userAddresses = action.payload;
            state.defaultAddress = action.payload?.find(address => address.isDefault === true);
            if (!state.selectedAddress?.id) {
                if (state.defaultAddress) {
                    state.selectedAddress = state.defaultAddress;
                } else {
                    state.selectedAddress = action.payload?.[0];
                }
            } else {
                state.selectedAddress = action.payload?.find(address => address.id === state.selectedAddress?.id);
            }

            state.selectedLocation = state.selectedAddress;
            state.loading = false;
            console.log("DEBUG 1")
            if (state?.selectedAddress) {
                console.log("DEBUG 2")
                state.selectedLocationDisplayText = state.selectedAddress?.address + ", " + state.selectedAddress?.city;
            } else {
                state.selectedLocationDisplayText = SelectLocationHeaderText
            }

            state.error = null;
            state.idCounter = action.payload.length;
            state.loading = false;
        },
        [fetchAddresses.rejected]: (state, action) => {
            state.error = action.error;
            state.loading = false;
        }
    }
});

export const {
    addAddress,
    editAddress,
    removeAddress,
    getSelectedLocationDisplayText,
    setSelectedAddress,
    setSelectedLocation,
    setLatLong,
    setIDCounter,
    setUerLocationFromClient,
    setUserLocation
} = userAddressSlice.actions;

export default userAddressSlice.reducer;
