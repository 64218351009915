

// sendEventToNative is used for sending events to native client
export const sendEventToNative = (screenName, eventAction, eventName, eventLabel) => {

    console.log("sendEventToNative", screenName, eventAction, eventName, eventLabel)
    if (window && window.Android && window.Android.sendEventToNative) {
        console.log("sendEventToNativeFOund", screenName, eventAction, eventName, eventLabel)
        window.Android.sendEventToNative(screenName, eventAction, eventName, JSON.stringify(eventLabel))
    }
}
