import { host } from "./index"
import { store } from "../../store/store"
import axios from 'axios';
import { supportedCodeLength, otpAutoDetectionSource, loginType, grantType, grantTypeRefreshToken } from "../../config/login";

// sendVerificationCode is used to sending OTP to user's phone number
export let sendVerificationCode = async (countryCode, phoneNumber) => {
    let state = await store.getState();
    // fetch device meta data from state
    //let data = state?.user.data;
    let deviceId = state?.user?.clientData?.deviceId;
    let clientId = state?.user?.clientData?.clientId;
    let clientSecret = state?.user?.clientData?.clientSecret;
    let deviceType = state?.user?.clientData?.deviceType;
    let sdkVersion = state?.user?.clientData?.sdkVersion;
    let appVersion = state?.user?.clientData?.appVersion;

    countryCode = countryCode.slice(1)

    let formdata = new FormData();
    formdata.append("deviceId", deviceId);
    formdata.append("clientId", clientId);
    formdata.append("client_id", clientId);
    formdata.append("client_secret", clientSecret);
    formdata.append("clientSecret", clientSecret);
    formdata.append("deviceType", deviceType);
    formdata.append("sdkVersion", sdkVersion);
    formdata.append("appVersion", appVersion);
    formdata.append("supportedCodeLength", supportedCodeLength);
    formdata.append("otpAutoDetectionSource", otpAutoDetectionSource);
    formdata.append("phoneNumber", phoneNumber);
    formdata.append("countryCode", countryCode);

    let url = `${host}/v4/users/generateVerificationCode`;

    let request = {
        method: 'POST',
        url: url,
        data: formdata,
    }

    return axios(request)
};

// verify User is used for validating user OTP
export let verifyUser = async (countryCode, phoneNumber, verificationCode) => {
    let state = await store.getState();

    let deviceId = state?.user.clientData.deviceId;
    let clientId = state?.user.clientData.clientId;
    let clientSecret = state?.user.clientData.clientSecret;
    let deviceType = state?.user.clientData.deviceType;
    let sdkVersion = state?.user.clientData.sdkVersion;
    let appVersion = state?.user.clientData.appVersion;

    countryCode = countryCode.slice(1)

    let formdata = new FormData();
    formdata.append("deviceId", deviceId);
    formdata.append("clientId", clientId);
    formdata.append("client_id", clientId);
    formdata.append("client_secret", clientSecret);
    formdata.append("clientSecret", clientSecret);
    formdata.append("deviceType", deviceType);
    formdata.append("sdkVersion", sdkVersion);
    formdata.append("appVersion", appVersion);
    formdata.append("supportedCodeLength", supportedCodeLength);
    formdata.append("otpAutoDetectionSource", otpAutoDetectionSource);
    formdata.append("phoneNumber", phoneNumber);
    formdata.append("countryCode", countryCode);
    formdata.append("verificationCode", verificationCode);
    formdata.append("loginType", loginType);
    formdata.append("grant_type", grantType);

    let url = `${host}/v4/users/verifyUser`;

    let request = {
        method: "POST",
        url: url,
        data: formdata,
    }

    return axios(request)
};

// regenerateAccessToken regenerates user access token using refresh token
export let regenerateAccessToken = async (refreshToken) => {
    let state = await store.getState();

    let deviceId = state?.user.clientData.deviceId;
    let clientId = state?.user.clientData.clientId;
    let clientSecret = state?.user.clientData.clientSecret;
    let deviceType = state?.user.clientData.deviceType;
    let sdkVersion = state?.user.clientData.sdkVersion;
    let appVersion = state?.user.clientData.appVersion;

    let url = `${host}/v4/users/generateAccessToken`;

    let formdata = new FormData();
    formdata.append("deviceId", deviceId);
    formdata.append("clientId", clientId);
    formdata.append("client_id", clientId);
    formdata.append("client_secret", clientSecret);
    formdata.append("clientSecret", clientSecret);
    formdata.append("deviceType", deviceType);
    formdata.append("sdkVersion", sdkVersion);
    formdata.append("appVersion", appVersion);
    formdata.append("refresh_token", refreshToken);
    formdata.append("grant_type", grantTypeRefreshToken);

    let request = {
        method: "POST",
        url: url,
        data: formdata,
    }

    return axios(request)
};

export let getUserMetaData = async ({ accessToken, phoneNumber }) => {

    let url = `${host}/v1/users/metadata?accessToken=${accessToken}`;

    if (phoneNumber && phoneNumber.length > 0) {
        url = `${url}&phoneNumber=${phoneNumber}`
    }

    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)

}

export let getUserMetaDataWithRetry = async ({ accessToken }) => {
    let url = `${host}/v1/users/metadata?accessToken=${accessToken}`;

    let request = {
        method: "GET",
        url: url,
    }

    return axios(request)

}

export let regenerateAccessTokenWithRetry = async (payload) => {
    let { refreshToken, deviceId, clientId, clientSecret, deviceType, sdkVersion, appVersion } = payload;
    let url = `${host}/v4/users/generateAccessToken`;

    let formdata = new FormData();
    formdata.append("deviceId", deviceId);
    formdata.append("clientId", clientId);
    formdata.append("client_id", clientId);
    formdata.append("client_secret", clientSecret);
    formdata.append("clientSecret", clientSecret);
    formdata.append("deviceType", deviceType);
    formdata.append("sdkVersion", sdkVersion);
    formdata.append("appVersion", appVersion);
    formdata.append("refresh_token", refreshToken);
    formdata.append("grant_type", grantTypeRefreshToken);

    let request = {
        method: "POST",
        url: url,
        data: formdata,
    }

    return axios(request)
};

export let registerWithDeviceID = async ({ deviceId }) => {
    let url = `${host}/v1/superApps/users/register/device`;

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify({ deviceId }),
    }

    return axios(request)
}

export let dpandaLogin = async ({ phoneNumber, generatedUserId }) => {
    let state = await store.getState();
    let deviceId = state?.user?.clientData?.deviceId;
    let userId = state?.user?.data?.userId;
    let url = `${host}/v1/superApps/users/dpanda/login`;
    if(deviceId != null){
        url = url + "?deviceId=" + deviceId
    }
    if(userId != null && deviceId != null){
        url = url + "&userId=" + userId
    } else if(userId != null){
        url = url + "?userId=" + userId
    }

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify({ phoneNumber, uuid: generatedUserId }),
    }

    return axios(request)
}