import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { maxSearchHistoryLength } from '../../config/index';
import { getUserMetaData, regenerateAccessToken } from '../../util/apiUtils/login';

export let fetchUsermetadata = createAsyncThunk('user/fetchUsermetadata', async ({ accessToken, refreshToken }) => {
    let res = await getUserMetaData(accessToken);
    let response = res?.data
    // if access token is expired, then regenerate access token and try again
    if (res.response?.status === 401) {
        let res = await regenerateAccessToken(refreshToken);
        let refreshedTokens = res?.data
        if (refreshedTokens?.access_token) {
            accessToken = refreshedTokens.access_token;
            dispatch(setAccessToken(accessToken));
            dispatch(setRefreshToken(refreshedTokens.refresh_token));
            dispatch(setTokenType(refreshedTokens.token_type));
            dispatch(setExpiresIn(refreshedTokens.expires_in));
            response = await getUserMetaData(accessToken);
        }

    }

    return response
})

export let regenerateAuthTokens = createAsyncThunk('user/regenerateAuthTokens', async (refreshToken) => {
    let response = await regenerateAccessToken(refreshToken);
    let refreshedTokens = response?.data
    if (response?.data?.access_token) {
        dispatch(setAccessToken(accessToken));
        dispatch(setRefreshToken(refreshedTokens?.refresh_token));
        dispatch(setTokenType(refreshedTokens?.token_type));
        dispatch(setExpiresIn(refreshedTokens?.expires_in));
        response = await getUserMetaData(refreshedTokens?.access_token);
    }
    return response
})

const userSlice = createSlice({
    name: 'user',
    initialState: {
        clientData: {
            appVersion: null,
            clientId: "",
            clientSecret: "",
            countryCode: '91',
            deviceId: "",
            deviceType: "",
            sdkVersion: "",
            loginType: "sms",
            phoneNumber: '',
            supportedCodeLength: 6,
            otpAutoDetectionSource: "google_sms_retriever_api",
            grantType: "password",
            packageName: "",
            sessionId: "",
            languageCode: "en",
            timezone: "Asia/Kolkata",
            webViewId: "",
            webViewSource: "",
            webViewScreenName: "",
            screenName : ""
        },
        data: {
            userId: null,
            cloudAccountId: null,
            numberOfUserCharacters: 0,
            dpandaAuthtoken: null,
            dpandaAuthTokenExpiry: 0,
            name : '',
            generatedUserId: ''
        },
        authData: {
            accessToken: '',
            refreshToken: '',
            tokenType: '',
            expiresIn: null,
            phoneNumber: '',
        },
        redirectionData: {
            redirect: '',
            vendorId: '',
            vendor: '',
        },
        dpandaAuthtoken: '',
        isDeviceRegistered: false,
        loading: false,
        error: null,
        searchHistory: [],
        installedApps: [],
        trendingSearchKeywords: [],
        isPackagesInstalled: {},
    },
    reducers: {
        setUserData: (state, action) => {
            state.data = action.payload;
        },
        addToSearchHistory: (state, action) => {
            state.searchHistory.unshift(action.payload);
            state.searchHistory = [...new Set(state.searchHistory)];
            state.searchHistory = state.searchHistory.slice(0, maxSearchHistoryLength);

        },
        addToTrendingSearchKeyword: (state, action) => {
            state.trendingSearchKeywords = action.payload;
        },
        setAccessToken: (state, action) => {
            state.authData.accessToken = action.payload;
        },
        setRefreshToken: (state, action) => {
            state.authData.refreshToken = action.payload;
        },
        setUserId: (state, action) => {
            state.data.userId = action.payload;
        },
        setGeneratedUserId: (state, action) => {
            state.data.generatedUserId = action.payload;
        },
        setCloudAccountId: (state, action) => {
            state.data.cloudAccountId = action.payload;
        },
        resetAccessToken: (state, action) => {
            state.data.accessToken = '';
        },
        resetUser: (state, action) => {
            state.data = {};
            state.token = '';
            state.id = null;
            state.cloudAccountId = null;
        },
        setClientData: (state, action) => {
            state.clientData = action.payload;
        },
        setUserMetaData: (state, action) => {
            state.data.userId = action.payload.userId;
            state.data.cloudAccountId = action.payload.cloudAccountId;
        },
        setPhoneNumber: (state, action) => {
            state.clientData.phoneNumber = action.payload;
        },
        setName: (state, action) => {
            state.data.name = action.payload;
        },
        setCountryCode: (state, action) => {
            state.clientData.countryCode = action.payload;
        },
        setUserAuthData: (state, action) => {
            state.authData = action.payload;
        },
        setDpandaAuthToken: (state, action) => {
            console.log('setDpandaAuthToken', action.payload)
            state.data.dpandaAuthtoken = action.payload?.dpandaAuthtoken;
            state.data.dpandaAuthTokenExpiry = action.payload?.dpandaAuthTokenExpiry;
        },
        setAuthDataPhoneNumber: (state, action) => {
            state.authData = { ...state.authData, phoneNumber: action.payload };
        },
        setInstalledApps: (state, action) => {
            state.installedApps = action.payload;
        },
        setRedirectionData: (state, action) => {
            state.redirectionData = action.payload;
        },
        setIsPackageInstalled: (state, action) => {
            state.isPackagesInstalled = action.payload;
        },
        setIsDeviceRegistered: (state, action) => {
            state.isDeviceRegistered = action.payload;
        }
    },
    extraReducers: {
        [fetchUsermetadata.fulfilled]: (state, action) => {
            state.data.userId = action.payload.userId;
            state.data.cloudAccountId = action.payload.cloudAccountId;
        },
        [fetchUsermetadata.rejected]: (state, action) => {
            state.error = action.payload;
        },
        [fetchUsermetadata.pending]: (state, action) => {
            state.loading = true;
        },
        [regenerateAuthTokens.fulfilled]: (state, action) => {
            state.data.accessToken = action.payload.access_token;
            state.data.refreshToken = action.payload.refresh_token;
            state.data.tokenType = action.payload.token_type;
            state.data.expiresIn = action.payload.expires_in;
        },
        [regenerateAuthTokens.rejected]: (state, action) => {
            state.error = action.payload;
        },
        [regenerateAuthTokens.pending]: (state, action) => {
            state.loading = true;
        }

    }
});

export const { setUserData, addToSearchHistory, setCloudAccountId, setUserId, setInstalledApps,
    resetUser, resetAccessToken, setAccessToken, setUserMetaData, setRefreshToken,
    setUserAuthData, setDpandaAuthToken, setClientData, setAuthDataPhoneNumber, setName,
    setRedirectionData,addToTrendingSearchKeyword,setIsPackageInstalled, setGeneratedUserId, setIsDeviceRegistered } = userSlice.actions;

export default userSlice.reducer;
