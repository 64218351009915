import { v4 as uuidv4 } from 'uuid';
import { addProductIds, addBannerIds, addSuperAppIds } from "../../store/slices/events";
import { store } from "../../store/store";
import { sendEventToNative } from "./interfaces";

// screens
export const KBSuperApp = "kb_super_app";
export const BobbleSuperApp = "app_super_app";

// event actions
const SuperApp = "Super app";

// App screen
const AppScreen = "app_screen";
// Event Action
const EventAction = "feature";
// ViewAction
const ViewAction = "view";
// EarnRewardAction
const EarnRewardsAction = "earn_rewards";
// FeatureAction
const FeatureAction = "feature";
// SettingAction
const SettingAction = "setting";
// Share action
const ShareAction = "share";

// BobbleRewards
export const BobbleRewards = "bobble_app_rewards";
// Login action
export const LoginAction = "login";

// INFO: cart events
export const CartIconClickedEvent = "cart_icon_clicked";
export const CartViewedEvent = "cart_viewed";
export const PlaceOrderClickedEvent = "place_order_clicked";
export const OrderSummaryViewedEvent = "order_summary_viewed";

export const PlaceOrderClickScreens = {
    "cart": "cart",
    "orderSummary": "order_summary",
    "address": "address",
};

// INFO: address events
export const SelectDeliveryLocationClickedEvent = "select_delivery_location_clicked";
export const AddNewAddressClickedEvent = "add_new_address_clicked";
export const AddNewAddressLandedEvent = "add_new_address_landed";
export const SaveNewAddressClickedEvent = "save_new_address_clicked";
export const SavedAddressSelectedEvent = "saved_address_selected";
export const LocationRequestedEvent = "location_requested";
export const LocationReceivedEvent = "location_shared";

export const AddressScreens = {
    "superAppPage": "super_app_page",
    "productDetail": "product_detail",
};

// INFO: Login Events

export const LoginPageViewedEvent = "login_page_viewed";
export const LoginOptionClickedEvent = "login_option_clicked";
export const LoginPhoneClickedEvent = "login_phone_clicked";
export const LoginOTPEvent = "login_otp";
export const LoginSocialClickedEvent = "login_social_clicked";

export const SocialPlatforms = {
    "truecaller": "truecaller",
    "phone": "phone",
}

export const PlatformOptions = {
    "phone": "phone",
    "email": "email",
    "social": "social",
}

// INFO: Super App Events

export const WishlistIconClickedEvent = "wishlist_icon_clicked";
export const MyOrdersIconClickedEvent = "my_orders_icon_clicked";
export const MyOrdersViewedEvent = "my_orders_viewed";
export const RequestMessageSubmittedEvent = "request_message_submitted";
export const ProductListingSearchClickedEvent = "product_listing_search_clicked";
export const ProductListingSearchResponseEvent = "product_listing_search_response";
export const SpecialOffersViewedEvent = "special_offers_viewed";
export const SpecialOffersClickedEvent = "special_offers_clicked";
export const PartnerServicesViewedEvent = "partner_services_viewed";
export const PartnerServicesClickedEvent = "partner_services_clicked";
export const PartnerServicesMoreClickedEvent = "partner_services_more_clicked";
export const PartnerServicesViewLessClickedEvent = "partner_services_view_less_clicked";

// INFO: Reward System Events
export const RewardsPageViewedEvent = "rewards_page_viewed";
// export const RewardsCoinEarnedEvent = "rewards_coin_earned";
export const RewardsPageActionClickedEvent = "rewards_page_action_clicked";
export const RewardsRedeemViewedEvent = "rewards_redeem_viewed";
export const RewardsRedeemClickedEvent = "rewards_redeem_clicked";
export const RewardsInvitePageViewedEvent = "rewards_invite_page_viewed";
export const InviteFriendsEvent = "invite_friends";
export const GenericContentSharedEvent = "generic_content_shared";
export const AnimationPopupViewedEvent = "animation_popup_viewed";
export const AnimationPopupClickedEvent = "animation_popup_clicked";

export const SuperAppScreens = {
    "superAppPage": "super_app_page",
    "productDetail": "product_detail",
    "productListing": "product_listing",
};

export const OTPTypes = {
    "phone": "phone",
    "email": "email",
};

export const SearchIconScreens = {
    "/bobblexclusive/home": "super_app_page",
    "/bobblexclusive/products/[id]": "product_detail",
    "/bobblexclusive/products": "product_listing",
    "/bobblexclusive/categories": "categories"
};

// Error events 
export const SuperAppErrorOccured = "super_app_error_occured";

export const EventStatus = {
    "success": "success",
    "failure": "failure",
    "pending": "pending",
}

export const ProductListingScreens = {
    "/bobblexclusive/home": "super_app_page",
    "/bobblexclusive/products": "product_listing_page",
    "/bobblexclusive/wishlist": "wishlist",
    "/bobblexclusive/products?searchTerm": "product_search",
    "/bobblexclusive/products/[id]": "product_detail",
    "/bobblexclusive/carts/[id]": "order_summary",
}

export const ActionsTypes = {
    "viewed": "viewed",
    "clicked": "clicked",
    "searched": "searched",
    "productSharing ": "product_sharing",
    "addToCart": "add_to_cart",
    "removeFromCart": "remove_from_cart",
    "addToWishlist": "add_to_wishlist",
    "removeFromWishlist": "remove_from_wishlist",
    "regenerateTokens": "regenerate_tokens",
    "login": "login",
    "vendorLogin": "vendor_login",
    "viewOrderHistory": "view_order_history",
    "viewOrderDetails": "view_order_details",
    "viewOrderSummary": "view_order_summary",
    "viewCart": "view_cart",
    "placeOrder": "place_order",
    "viewAddress": "view_address",
    "addAddress": "add_address",
    "editAddress": "edit_address",
    "getUserMetaData": "get_user_meta_data",
    "requestProducts": "request_products",
}

export const ServiceTypes = {
    "requestProducts": "request_products",
    "productListing": "product_listing",
    "specialOffers": "special_offers",
    "partnerService": "partner_service",
    "categoriesListing": "categories_listing"
}

export const SuperAppViewedFlows = {
    "location": "location api",
    "webUrl": "web flow",
    "browser": "web flow",
    "internalWebviewRedirection": "web flow",
    "deeplink": "application",
    "deeplink_redirect": "application",
}

export const AddressTypes = {
    "location": "location",
    "address": "address"
}

export const ProductListingViewedEvent = "product_listing_viewed";
export const ProductListingCategoryClickedEvent = "product_listing_category_clicked";
export const ProductListingBackClickedEvent = "product_listing_back_clicked";
export const ProductListingViewAllClickedEvent = "product_listing_view_all_clicked";
export const ProductListingViewClickedEvent = "product_listing_view_clicked";
export const ProductListingShareClickedEvent = "product_listing_share_clicked";
export const ProductAddCartClickedEvent = "product_add_cart_clicked";
export const ProductAddWishlistClickedEvent = "product_add_wishlist_clicked";
export const ProductSortFilterClickedEvent = "product_sort_filter_clicked";
export const ProductSortFilterSavedEvent = "product_sort_filter_saved";
export const ProductShortcutFilterClickedEvent = "product_shortcut_filter_clicked";

export const SuperAppWebLoaderTimeEvent = "super_app_web_loader_time"
export const SuperAppWebSplashTimeEvent = "super_app_web_splash_time"

//My Account Events
export const AccountMenuClickedEvent = "account_menu_clicked";
export const OrderTrackingClickedEvent = "order_tracking_clicked";
export const CancelOrderClickedEvent = "cancel_order_clicked";
export const WishlistViewedEvent = "wishlist_viewed";
export const SavedAddressViewedEvent = "saved_address_viewed";

export const TopDealsViewedEvent = "top_deals_viewed";
export const TopDealsClickedEvent = "top_deals_clicked";
export const ProductCartQuantityChangedEvent = "product_cart_quantity_changed";
export const CategoryBannerClickedEvent = "category_banner_clicked";

export const dispatchEventToNative = (screenName, eventName, eventData, eventAction) => {

    const state = store?.getState();

    let eventID = uuidv4();
    let eventLabel = {}
    let _screenName = screenName ?? KBSuperApp;
    let _eventAction = eventAction ?? SuperApp;
    let rewardScreenName = state?.user?.clientData?.webViewScreenName ?? BobbleRewards;

    switch (eventName) {

        case SuperAppWebLoaderTimeEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["loader_id"] = eventData?.loader_id;
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["webview_url"] = eventData?.webview_url
            eventLabel["flow"] = eventData?.flow;
            eventLabel["timestamp"] = eventData?.timestamp
            eventLabel["action"] = eventData?.action
            console.log("SuperAppWebLoaderTimeEvent", eventLabel);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;
        case SuperAppWebSplashTimeEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["loader_id"] = eventData?.loader_id;
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["webview_url"] = eventData?.webview_url
            eventLabel["flow"] = eventData?.flow;
            eventLabel["timestamp"] = eventData?.timestamp
            eventLabel["action"] = eventData?.action
            console.log("SuperAppWebSplashTimeEvent", eventLabel);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case CartIconClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["flow"] = eventData?.flow;
            console.log("CartIconClickedEvent", eventLabel);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        //Cart is removed so event is disabled
        case CartViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["is_empty"] = eventData?.isEmpty ? parseInt(eventData?.isEmpty) : 0;
            eventLabel["vendor_list"] = eventData?.vendorList?.length > 0 ? eventData?.vendorList?.split(",").map(item => isNaN(parseInt(item)) ? 0 : parseInt(item)) : [];

            console.log("CartViewedEvent", eventLabel);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case PlaceOrderClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id;
            eventLabel["screen"] = eventData?.screen;
            console.log("PlaceOrderClickedEvent", eventLabel);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case OrderSummaryViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["vendor_id"] = eventData?.vendorId;
            eventLabel["product_id"] = eventData?.productId?.length > 0 ? eventData?.productId?.split(",").map(item => isNaN(parseInt(item)) ? 0 : parseInt(item)) : [];
            _screenName = eventData?.screenName ? eventData?.screenName : KBSuperApp;
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("OrderSummaryViewedEvent", eventLabel);
            break;

        // Address events
        case SelectDeliveryLocationClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["flow"] = eventData?.flow;
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("SelectDeliveryLocationClickedEvent", eventLabel);
            break;

        case AddNewAddressClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["type"] = eventData?.type;
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("AddNewAddressClickedEvent", eventLabel);
            break;

        case AddNewAddressLandedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["is_autofill"] = eventData?.isAutofill ? parseInt(eventData?.isAutofill) : 0;
            eventLabel["type"] = eventData?.type;
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("AddNewAddressLandedEvent", eventLabel);
            break;

        case SaveNewAddressClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["status"] = eventData?.status;
            eventLabel["address_type"] = eventData?.address_type;
            eventLabel["type"] = eventData?.type;
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("SaveNewAddressClickedEvent", eventLabel);
            break;

        case SavedAddressSelectedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["address_type"] = eventData?.address_type;
            eventLabel["type"] = eventData?.type;
            sendEventToNative(KBSuperApp, SuperApp, eventName, eventLabel);
            console.log("SavedAddressSelectedEvent", eventLabel);
            break;

        //PENDING TBD
        case LocationRequestedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["latitude"] = eventData?.latitude ?? ""
            eventLabel["longitude"] = eventData?.longitude ?? ""
            eventLabel["city"] = eventData?.city ?? ""
            eventLabel["state"] = eventData?.state ?? ""
            eventLabel["country"] = eventData?.country ?? ""
            eventLabel["status"] = eventData.status
            eventLabel["is_icon"] = 0

            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("LocationRequestedEvent", eventLabel);
            break;

        //PENDING TBD
        case LocationReceivedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["latitude"] = eventData?.latitude ?? ""
            eventLabel["longitude"] = eventData?.longitude ?? ""
            eventLabel["city"] = eventData?.city ?? ""
            eventLabel["state"] = eventData?.state ?? ""
            eventLabel["country"] = eventData?.country ?? ""
            eventLabel["status"] = eventData.status
            eventLabel["is_icon"] = 0

            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("LocationReceivedEvent", eventLabel);
            break;

        // INFO: login events

        case LoginPageViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["login_phone"] = eventData?.login_phone ? parseInt(eventData?.login_phone) : 0;
            eventLabel["login_social"] = eventData?.login_social ? parseInt(eventData?.login_social) : 0;
            eventLabel["login_email"] = eventData?.login_email ? parseInt(eventData?.login_email) : 0;
            eventLabel["flow"] = eventData?.flow
            sendEventToNative(_screenName, _eventAction, eventName, eventLabel);
            console.log("LoginPageViewedEvent", eventLabel);
            break;

        case LoginOptionClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["option"] = eventData?.option;
            eventLabel["social_platform"] = eventData?.social_platform;

            sendEventToNative(_screenName, _eventAction, eventName, eventLabel);
            console.log("LoginOptionClickedEvent", eventLabel);
            break;

        case LoginPhoneClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["is_skipped"] = eventData?.is_skipped ? parseInt(eventData?.is_skipped) : 0;
            eventLabel["is_auto_filled"] = eventData?.is_auto_filled ? parseInt(eventData?.is_auto_filled) : 0;
            eventLabel["error_message"] = eventData?.error_message ?? '';

            sendEventToNative(_screenName, _eventAction, eventName, eventLabel);
            console.log("LoginPhoneClickedEvent", eventLabel);
            break;

        case LoginOTPEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["is_skipped"] = eventData?.is_skipped ? parseInt(eventData?.is_skipped) : 0;
            eventLabel["otp_type"] = eventData?.otp_type;
            eventLabel["error_message"] = eventData?.error_message ?? '';
            eventLabel["otp_counter"] = eventData?.otp_counter ? parseInt(eventData?.otp_counter) : 0;
            eventLabel["is_max_limit"] = eventData?.is_max_limit ? parseInt(eventData?.is_max_limit) : 0;
            eventLabel["login_status"] = eventData?.login_status ? parseInt(eventData?.login_status) : 0;

            sendEventToNative(_screenName, _eventAction, eventName, eventLabel);
            console.log("LoginOTPEvent", eventLabel);
            break;

        //PENDING TBD
        case LoginSocialClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["login_status"] = eventData?.login_status ? parseInt(eventData?.login_status) : 0;
            eventLabel["social_platform"] = eventData?.social_platform;
            eventLabel["error_message"] = eventData?.error_message;
            sendEventToNative(_screenName, _eventAction, eventName, eventLabel);
            console.log("LoginSocialClickedEvent", eventLabel);
            break;

        // INFO: Super app events
        //PENDING TBD
        case SpecialOffersViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            let offer = {}
            offer["index"] = eventData?.index ?? '';
            offer["campaign_id"] = eventData?.campaignId ?? '';
            offer["banner_id"] = eventData?.bannerId ?? '';
            eventLabel["offer"] = offer;

            if (!state?.events?.bannerIds?.includes(eventData?.bannerId)) {
                sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
                console.log("SpecialOffersViewedEvent", eventLabel);
                store.dispatch(addBannerIds(eventData?.bannerId));
            }
            break;

        case SpecialOffersClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["campaign_id"] = eventData?.campaign_id ?? '';
            eventLabel["action_performed"] = eventData?.action_performed ?? '';
            eventLabel["banner_id"] = eventData?.banner_id ?? '';
            eventLabel["index"] = eventData?.index ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("SpecialOffersClickedEvent", eventLabel);
            break;

        case WishlistIconClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("WishlistIconClickedEvent", eventLabel);
            break;

        case MyOrdersIconClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("MyOrdersIconClickedEvent", eventLabel);
            break;

        case MyOrdersViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            let orders = []
            eventData?.orders?.forEach((order) => {
                orders.push(
                    {
                        "order_id": order?.orderId ?? '',
                        "status": order?.orderStatus ?? '',
                    }
                )
            })
            eventLabel["orders"] = orders
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("MyOrdersViewedEvent", eventLabel);
            break;

        case RequestMessageSubmittedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("RequestMessageSubmittedEvent", eventLabel);
            break;

        case ProductListingSearchClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingSearchClickedEvent", eventLabel);
            break;

        case ProductListingSearchResponseEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            eventLabel["search_text"] = eventData?.search_text ?? '';
            eventLabel["response"] = eventData?.response ? parseInt(eventData?.response) : 0;
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingSearchResponseEvent", eventLabel);
            break;

        case PartnerServicesViewedEvent:
            let partners = {
                superapp_id: eventData?.superappId ?? '',
                partner_index: eventData?.partnerIndex ?? ''
            }
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["partners"] = partners;

            if (!state?.events?.superAppIds?.includes(eventData?.superappId)) {
                sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
                console.log("PartnerServicesViewedEvent", eventLabel);
                store.dispatch(addSuperAppIds(eventData?.superappId));
            }
            break;

        case PartnerServicesClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["superapp_id"] = eventData?.superAddId ?? '';
            eventLabel["action_performed"] = eventData?.actionPerformed ?? '';
            eventLabel["partner_url"] = eventData?.partnerUrl ?? '';
            eventLabel["flow"] = "super_app_page"

            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("PartnerServicesClickedEvent", eventLabel);
            break;

        case PartnerServicesMoreClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("PartnerServicesMoreClickedEvent", eventLabel);
            break;

        case PartnerServicesViewLessClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("PartnerServicesViewLessClickedEvent", eventLabel);
            break;

        // INFO: Product listing events

        case ProductListingViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';

            let product = {}
            product["vendor_id"] = eventData?.vendorId ?? '';
            product["product_category_id"] = eventData?.productCategoryId ?? '';
            product["product_id"] = eventData?.productId ?? '';
            product["product_index"] = eventData?.productIndex ?? '';
            product["category_index"] = eventData?.categoryIndex ?? '';

            eventLabel["product"] = product;

            if (!state?.events?.productIds?.includes(eventData?.productId)) {
                sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
                console.log("ProductListingViewedEvent", eventLabel);
                store.dispatch(addProductIds(eventData?.productId));
            }

            break;

        case ProductListingCategoryClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingCategoryClickedEvent", eventLabel);
            break;

        case ProductListingBackClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingBackClickedEvent", eventLabel);
            break;

        case ProductListingViewAllClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            eventLabel["category_index"] = eventData?.category_index ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingViewAllClickedEvent", eventLabel);
            break;

        case ProductListingViewClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            eventLabel["product_id"] = eventData?.product_id ?? '';
            eventLabel["category_index"] = eventData?.category_index ?? '';
            eventLabel["product_index"] = eventData?.product_index ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingViewClickedEvent", eventLabel);
            break;

        case ProductListingShareClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            eventLabel["product_id"] = eventData?.product_id ?? '';
            eventLabel["category_index"] = eventData?.category_index ?? '';
            eventLabel["product_index"] = eventData?.product_index ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductListingShareClickedEvent", eventLabel);
            break;

        case ProductAddCartClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            eventLabel["product_id"] = eventData?.product_id ?? '';
            eventLabel["category_index"] = eventData?.category_index ?? '';
            eventLabel["product_index"] = eventData?.product_index ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductAddCartClickedEvent", eventLabel);
            break;

        case ProductAddWishlistClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_id"] = eventData?.product_id ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductAddWishlistClickedEvent", eventLabel);
            break;

        case ProductSortFilterClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            eventLabel["icon_type"] = eventData?.icon_type ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductSortFilterClickedEvent", eventLabel);
            break;

        case ProductSortFilterSavedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            eventLabel["icon_type"] = eventData?.icon_type ?? '';
            eventLabel["type"] = eventData?.type ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductSortFilterSavedEvent", eventLabel);
            break;

        case ProductShortcutFilterClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["filter_index"] = eventData?.filter_index ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            console.log("eventData", eventData);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("ProductShortcutFilterClickedEvent", eventLabel);
            break;

        //PENDING IN CART SLICE AND DATA SYNC
        case SuperAppErrorOccured:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["error_message"] = eventData?.error_message ?? '';
            eventLabel["error_code"] = eventData?.error_code ?? '';
            eventLabel["service_type"] = eventData?.service_type ?? '';
            eventLabel["action_type"] = eventData?.action_type ?? '';
            eventLabel["partner_url"] = ""
            eventLabel["partner_package_name"] = ""
            eventLabel["superapp_id"] = ""

            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            console.log("SuperAppErrorOccured", eventLabel);
            break;

        case AccountMenuClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["type"] = eventData?.flow ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case OrderTrackingClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["order_id"] = eventData?.order_id ?? '';
            eventLabel["status"] = eventData?.status ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case CancelOrderClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["order_id"] = eventData?.order_id ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case WishlistViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_ids"] = eventData?.product_ids ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case SavedAddressViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["type"] = eventData?.type ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case TopDealsClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_id"] = eventData?.product_id ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case TopDealsViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["product_id"] = eventData?.productId ?? '';
            eventLabel["vendor_id"] = eventData?.vendorId ?? '';
            eventLabel["product_category_id"] = eventData?.productCategoryId ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case ProductCartQuantityChangedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["vendor_id"] = eventData?.vendor_id ?? '';
            eventLabel["product_category_id"] = eventData?.product_category_id ?? '';
            eventLabel["product_id"] = eventData?.product_id ?? '';
            eventLabel["category_index"] = eventData?.category_index ?? '';
            eventLabel["product_index"] = eventData?.product_index ?? '';
            eventLabel["flow"] = eventData?.flow ?? '';
            eventLabel["action"] = eventData?.action ?? '';
            eventLabel["count"] = eventData?.count ?? '';
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        case CategoryBannerClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["category_id"] = eventData?.category_id ?? '';
            console.log("CategoryBannerClickedEvent", eventLabel);
            sendEventToNative(_screenName, SuperApp, eventName, eventLabel);
            break;

        default:
            console.log("Unknown event", eventName);
            break;
    }
}

export const dispatchRewardsEventToNative = (screenName, eventName, eventData, eventAction) => {
    const state = store?.getState();

    let eventID = uuidv4();
    let eventLabel = {}
    let _eventAction = eventAction ?? SuperApp;
    let rewardScreenName = screenName ?? BobbleRewards;

    switch (eventName) {

        case RewardsPageViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["webview_id"] = state?.user?.clientData?.webViewId ?? '';
            eventLabel["logged_in"] = !!state?.user?.authData?.accessToken;

            eventLabel["redeemable_coins"] = eventData?.coins ?? 0;
            let pendingTasks = [];
            let completedTasks = [];
            for (let task of eventData?.tasks ?? []) {
                if (task.status === 'active') {
                    pendingTasks.push(task.id);
                } else if (task.status === 'completed') {
                    completedTasks.push(task.id);
                }
            }
            eventLabel["pending_task"] = pendingTasks;
            eventLabel["completed_task"] = completedTasks;

            sendEventToNative(rewardScreenName, ViewAction, eventName, eventLabel);
            console.log("RewardsPageViewedEvent", eventLabel);
            break;

        // case RewardsCoinEarnedEvent:
        //     eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
        //     eventLabel["webview_id"] = state?.user?.clientData?.webViewId ?? '';
        //     eventLabel["logged_in"] = !!state?.user?.authData?.accessToken;
        //
        //     eventLabel["reward_task"] = eventData?.taskID ?? '';
        //     eventLabel["coins_earned"] = eventData?.coinsEarned ?? 0;
        //     eventLabel["daily_reward_feature"] = eventData?.daily_reward_feature ?? '';
        //
        //     sendEventToNative(rewardScreenName, EarnRewardsAction, eventName, eventLabel);
        //     console.log("RewardsCoinEarnedEvent", eventLabel);
        //     break;

        case RewardsPageActionClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["webview_id"] = state?.user?.clientData?.webViewId ?? '';
            eventLabel["logged_in"] = !!state?.user?.authData?.accessToken;

            eventLabel["action_clicked"] = eventData?.actionClicked ?? '';
            eventLabel["task_id"] = eventData?.taskID ?? '';

            sendEventToNative(rewardScreenName, FeatureAction, eventName, eventLabel);
            console.log("RewardsPageActionClickedEvent", eventLabel);
            break;

        case RewardsRedeemViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["webview_id"] = state?.user?.clientData?.webViewId ?? '';
            eventLabel["logged_in"] = !!state?.user?.authData?.accessToken;

            eventLabel["redeemable_coins"] = eventData?.redeemableCoins ?? 0;
            eventLabel["is_eligible"] = eventData?.isEligible ?? 0;
            eventLabel["redeem_items"] = eventData?.items ?? [];

            sendEventToNative(rewardScreenName, FeatureAction, eventName, eventLabel);
            console.log("RewardsRedeemViewedEvent", eventLabel);
            break;

        case RewardsRedeemClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["webview_id"] = state?.user?.clientData?.webViewId ?? '';
            eventLabel["logged_in"] = !!state?.user?.authData?.accessToken;

            eventLabel["reward_id"] = eventData?.rewardID ?? '';
            eventLabel["reward_coins"] = eventData?.rewardCoins ?? 0;
            eventLabel["action"] = eventData?.event ?? '';
            eventLabel["is_success"] = eventData?.isSuccess ?? 0;

            sendEventToNative(rewardScreenName, FeatureAction, eventName, eventLabel);
            console.log("RewardsRedeemClickedEvent", eventLabel);
            break;

        case RewardsInvitePageViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["webview_id"] = state?.user?.clientData?.webViewId ?? '';
            eventLabel["logged_in"] = !!state?.user?.authData?.accessToken;

            eventLabel["invite_count"] = eventData?.inviteCount ?? 0;
            eventLabel["banner"] = eventData?.banner ?? '';
            eventLabel["packages_available"] = eventData?.packagesAvailable ?? [];

            sendEventToNative(rewardScreenName, FeatureAction, eventName, eventLabel);
            console.log("RewardsInvitePageViewedEvent", eventLabel);
            break;

        case InviteFriendsEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';

            eventLabel["is_clicked"] = eventData?.isClicked ?? 0;
            eventLabel["is_shared"] = eventData?.isShared ?? 0;
            eventLabel["shared_package_name"] = eventData?.sharedPackageName ?? '';
            eventLabel["shared_link"] = eventData?.sharedLink ?? '';
            eventLabel["shared_text"] = eventData?.sharedText ?? '';
            eventLabel["language_name"] = eventData?.languageName ?? '';

            sendEventToNative(rewardScreenName, SettingAction, eventName, eventLabel);
            console.log("InviteFriendsEvent", eventLabel);
            break;

        case GenericContentSharedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';

            eventLabel["content_type"] = eventData?.contentType ?? '';
            if (eventData?.contentType === 'text') {
                eventLabel["context_text"] = eventData?.contextText ?? '';
            }
            eventLabel["shared_package"] = eventData?.sharedPackage ?? '';

            sendEventToNative(rewardScreenName, ShareAction, eventName, eventLabel);
            console.log("GenericContentSharedEvent", eventLabel);
            break;


        case AnimationPopupViewedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["type"] = eventData?.type ?? '';

            sendEventToNative(rewardScreenName, SettingAction, eventName, eventLabel);
            console.log("AnimationPopupClickedEvent", eventLabel);
            break;

        case AnimationPopupClickedEvent:
            eventLabel["session_id"] = state?.user?.clientData?.sessionId ?? '';
            eventLabel["package_name"] = state?.user?.clientData?.packageName ?? '';
            eventLabel["type"] = eventData?.type ?? '';

            sendEventToNative(rewardScreenName, SettingAction, eventName, eventLabel);
            console.log("AnimationPopupClickedEvent", eventLabel);
            break;

        default:
            console.log("Unknown event", eventName);
            break;
    }
}