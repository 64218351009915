
export let storeInSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
};

export let retrieveFromSessionStorage = (key) => {
    let value = null;
    value = sessionStorage.getItem(key);
    return value;
};
