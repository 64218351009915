import {
    authTokenDataPoint,
    userMetadataDataPoint,
    installedAppsDataPoint,
    trueCallerLoginResponseDataPoint,
    userLocationDataPoint,
    intentFiltersDataPoint,
    shareProductDataPoint,
    superAppDataPoint,
    encryptedDataPoint,
    encryptedUserMetadataDataPoint,
    encryptedRegisterTasksDataPoint,
    encryptedRedeemRewardsDataPoint,
    truecallerLoginRewardSystemResponseDataPoint,
    loginRewardSystemResponseDataPoint, refreshRewardsDataPoint,
    isPackagesInstalledDataPoint, userMetadataForRewardsDataPoint
} from '../../config/js-interface-data-points';
import { parseClientData, getCountryLabelFromCode, returnValidStringFromRawInput } from '../../util/common';
import { store } from '../../store/store'
import { setUserAuthData, setClientData, setInstalledApps, resetUser, setEncryptedData, setIsPackageInstalled } from '../../store/slices/user';
import { initiateDataSync } from '../dataSync';
import { showToast, toastTypes, testToast } from '../common/toasts';
import { setSelectedLocation, setLatLong, setUerLocationFromClient } from '../../store/slices/userAddress';
import { setImageRedux, setRefreshRewardsHomeDataInternal } from '../../store/slices/global';
import {
    setIsProductSharingActive,
    setSharedProductId,
    setProductSharingState,
    setTrueCallerLoginOngoing,
    setTrueCallerLoginSuccess,
    setGetUserLocationFromClientOnGoing,
    setEncryptedUserMetadataData,
    setEncryptedRedeemRewardsData,
    setEncryptedRegisterTasksData,
    setRefreshRewardsHomeData
} from '../../store/slices/global';
import {
    dispatchEventToNative, LocationReceivedEvent,
    EventStatus, LoginSocialClickedEvent, SocialPlatforms
} from '../events';
import { env, LoginSuccessFull } from '../../config';
import Router from 'next/router'
import { errorDescription, truecallerLoginFailed } from '../../util/errors'


// invokeTruecallerLoginRewardSystemCallback
const invokeTruecallerLoginRewardSystemCallback = () => {
    let value = document.getElementById(truecallerLoginRewardSystemResponseDataPoint).value
    console.log("1 value revieved from client", value);
    _invokeTruecallerLoginCallback({ value: value, superAppDataSync: false });
}

// invokeLoginActivityCallback
const invokeLoginActivityCallback = () => {
    let value = document.getElementById(loginRewardSystemResponseDataPoint).value
    console.log("1 value revieved from client", value);
    _invokeLoginCallback({ value: value });
}

// invokeTruecallerLoginCallback
const invokeTruecallerLoginCallback = async () => {
    let value = document.getElementById(trueCallerLoginResponseDataPoint).value
    console.log("2 value revieved from client", value);
    _invokeTruecallerLoginCallback({ value: value, superAppDataSync: true });
}

const _invokeLoginCallback = async ({ value }) => {
    console.log("invokeLoginCallback Called")
    // const { dispatch } = store;
    // const redirectionData = store.getState()?.user?.redirectionData;
    // console.log("redirectionData", redirectionData)
    const { dispatch } = store;

    if (value?.length > 0) {
        let metaData = parseClientData(value);

        dispatch(setClientData({
            deviceId: metaData.deviceId,
            clientId: metaData.clientId,
            deviceType: metaData.deviceType,
            clientSecret: metaData.clientSecret,
            sdkVersion: metaData.sdkVersion,
            appVersion: metaData.appVersion,
            loginType: metaData.loginType,
            phoneNumber: metaData.phoneNumber,
            countryCode: metaData.countryCode,
            packageName: metaData.packageName,
            sessionId: metaData.sessionId,
            languageCode: metaData.languageCode,
            timezone: metaData.timezone,
            webViewId: metaData.webViewId,
            webViewSource: metaData.webViewSource,
            webViewScreenName: metaData.webViewScreenName,

        }));

        if (!(metaData?.accessToken?.length > 0 && metaData?.refreshToken?.length > 0 && metaData?.expiresIn?.length > 0)) {
            showToast(errorDescription(truecallerLoginFailed), toastTypes.error);

            document.getElementById(loginRewardSystemResponseDataPoint).value = "";
            return;
        }

        dispatch(setUserAuthData({
            accessToken: metaData.accessToken,
            refreshToken: metaData.refreshToken,
            tokenType: metaData.tokenType,
            expiresIn: metaData.expiresIn,
            phoneNumber: metaData.phoneNumber,
            countryCode: getCountryLabelFromCode(metaData.countryCode)
        }))
        console.log("Login Successfull 1")

        // Refresh rewards screen
        refreshRewardsCallback()


        // call user data sync function
    } else {
        // TODO: Revisit the error message

        console.log('2 Trucaller login failed. Please login via Phone Number')
        showToast(errorDescription(truecallerLoginFailed), toastTypes.error);
        document.getElementById(loginRewardSystemResponseDataPoint).value = "";
        return false;
    }

    // Reseting the value of the input tag to nil
    document.getElementById(loginRewardSystemResponseDataPoint).value = "";

}

// invokeTruecallerLoginCallback
const _invokeTruecallerLoginCallback = async ({ value, superAppDataSync }) => {
    console.log("invokeTruecallerLoginCallback Called")
    const { dispatch } = store;
    const redirectionData = store.getState()?.user?.redirectionData;
    console.log("redirectionData", redirectionData)

    // TODO: should return an empty value in case of failure from client

    if (value?.length > 0) {
        let metaData = parseClientData(value);

        let eventLabel = {};
        eventLabel["login_status"] = 1;
        eventLabel["social_platform"] = SocialPlatforms.truecaller;
        eventLabel["error_message"] = "";
        dispatchEventToNative(LoginSocialClickedEvent, eventLabel)
        dispatch(resetUser());

        dispatch(setClientData({
            deviceId: metaData.deviceId,
            clientId: metaData.clientId,
            deviceType: metaData.deviceType,
            clientSecret: metaData.clientSecret,
            sdkVersion: metaData.sdkVersion,
            appVersion: metaData.appVersion,
            loginType: metaData.loginType,
            phoneNumber: metaData.phoneNumber,
            countryCode: metaData.countryCode,
            packageName: metaData.packageName,
            sessionId: metaData.sessionId,
            screenName : metaData.screenName
        }));

        if (!(metaData?.accessToken?.length > 0 && metaData?.refreshToken?.length > 0 && metaData?.expiresIn?.length > 0)) {
            // TODO: Revisit the error message
            console.log('Trucaller login failed. Please login via Phone Number')
            showToast("metaData.accessToken: " + JSON.stringify(metaData.accessToken), toastTypes.info, env.DEV);
            showToast("metaData.refreshToken: " + JSON.stringify(metaData.refreshToken), toastTypes.info, env.DEV);
            showToast("metaData.expiresIn: " + JSON.stringify(metaData.expiresIn), toastTypes.info, env.DEV);
            showToast(errorDescription(truecallerLoginFailed), toastTypes.error);
            dispatch(setTrueCallerLoginOngoing(false))
            dispatch(setTrueCallerLoginSuccess(false));
            document.getElementById(trueCallerLoginResponseDataPoint).value = "";
            return;
        }

        dispatch(setUserAuthData({
            accessToken: metaData.accessToken,
            refreshToken: metaData.refreshToken,
            tokenType: metaData.tokenType,
            expiresIn: metaData.expiresIn,
            phoneNumber: metaData.phoneNumber,
            countryCode: getCountryLabelFromCode(metaData.countryCode)
        }))
        console.log("Login Successfull 1")

        let dataSyncSuccessful = true
        if (superAppDataSync) {
            dataSyncSuccessful = await initiateDataSync(metaData);
        }
        console.log("Login Successfull 2")

        if (dataSyncSuccessful) {
            showToast(LoginSuccessFull, toastTypes.success);
            dispatch(setTrueCallerLoginOngoing(false))
            dispatch(setTrueCallerLoginSuccess(true));
            console.log("Redirection Data 1:: ", JSON.stringify(redirectionData))
            console.log("URL for redirection:: ", redirectionData?.redirect)
            if (redirectionData?.redirect?.length > 0) {
                let redirectionUrl = redirectionData?.redirect;
                if (redirectionData?.vendorId != null) {
                    redirectionUrl = redirectionUrl + `&vendorId=${redirectionData?.vendorId}`
                }
                Router?.replace(redirectionUrl);
                document.getElementById(trueCallerLoginResponseDataPoint).value = "";
                return
            }

            Router?.replace(`/bobblexclusive/home`);
            console.log("Redirection Data 4:: ", JSON.stringify(redirectionData))
        } else {
            dispatch(setTrueCallerLoginSuccess(false));
            dispatch(setTrueCallerLoginOngoing(false))
            console.log('2 Trucaller login failed. Please login via Phone Number')
            showToast(errorDescription(truecallerLoginFailed), toastTypes.error);
            document.getElementById(trueCallerLoginResponseDataPoint).value = "";
            return false;
        }

        // call user data sync function
    } else {
        // TODO: Revisit the error message
        dispatch(setTrueCallerLoginSuccess(false));
        dispatch(setTrueCallerLoginOngoing(false))
        console.log('2 Trucaller login failed. Please login via Phone Number')
        showToast(errorDescription(truecallerLoginFailed), toastTypes.error);
        document.getElementById(trueCallerLoginResponseDataPoint).value = "";
        return false;
    }

    // Reseting the value of the input tag to nil
    document.getElementById(trueCallerLoginResponseDataPoint).value = "";
}

// getUserMetaDataCallback
const getUserMetaDataForRewardsCallback = () => {
    console.log("getUserMetaDataCallback Called")
    let syncData = true;
    const { dispatch } = store;
    const accessToken = store?.getState().user.authData.accessToken;

    let value = document.getElementById(userMetadataForRewardsDataPoint).value;
    console.log("value revieved from client", value);
    if (value) {
        let metaData = parseClientData(value);


        dispatch(setClientData({
            deviceId: metaData.deviceId,
            clientId: metaData.clientId,
            deviceType: metaData.deviceType,
            clientSecret: metaData.clientSecret,
            sdkVersion: metaData.sdkVersion,
            appVersion: metaData.appVersion,
            loginType: metaData.loginType,
            phoneNumber: metaData.phoneNumber,
            countryCode: metaData.countryCode,
            packageName: metaData.packageName,
            sessionId: metaData.sessionId,
            languageCode: metaData.languageCode,
            timezone: metaData.timezone,
            webViewId: metaData.webViewId,
            webViewSource: metaData.webViewSource,
            webViewScreenName: metaData.webViewScreenName,
        }));

        dispatch(setUserAuthData({
            accessToken: metaData.accessToken,
            refreshToken: metaData.refreshToken,
            tokenType: metaData.tokenType,
            expiresIn: metaData.expiresIn,
            phoneNumber: metaData.phoneNumber,
            countryCode: getCountryLabelFromCode(metaData.countryCode)
        }))

        let refreshValue = new Date().toString()
        console.log("refreshValue", refreshValue)
        dispatch(setRefreshRewardsHomeDataInternal(refreshValue));

    }

    // Reseting the value of the input tag to nil
    document.getElementById(userMetadataForRewardsDataPoint).value = "";
}

// getUserMetaDataCallback 
const getUserMetaDataCallback = () => {
    console.log("getUserMetaDataCallback Called")
    let syncData = true;
    const { dispatch } = store;
    const accessToken = store?.getState().user.authData.accessToken;
    const generatedUserId = store?.getState().user?.data?.generatedUserId;

    let value = document.getElementById(userMetadataDataPoint).value;
    console.log("value revieved from client", value);
    if (value) {
        let metaData = parseClientData(value);

        if ((metaData.accessToken && metaData.accessToken != "") && (generatedUserId && generatedUserId != "")) {
            dispatch(resetUser())
        }

        dispatch(setClientData({
            deviceId: metaData.deviceId,
            clientId: metaData.clientId,
            deviceType: metaData.deviceType,
            clientSecret: metaData.clientSecret,
            sdkVersion: metaData.sdkVersion,
            appVersion: metaData.appVersion,
            loginType: metaData.loginType,
            phoneNumber: metaData.phoneNumber,
            countryCode: metaData.countryCode,
            packageName: metaData.packageName,
            sessionId: metaData.sessionId,
            languageCode: metaData.languageCode,
            timezone: metaData.timezone,
            webViewId: metaData.webViewId,
            webViewSource: metaData.webViewSource,
            webViewScreenName: metaData.webViewScreenName,
            screenName : metaData.screenName
        }));

        dispatch(setUserAuthData({
            accessToken: metaData.accessToken,
            refreshToken: metaData.refreshToken,
            tokenType: metaData.tokenType,
            expiresIn: metaData.expiresIn,
            phoneNumber: metaData.phoneNumber,
            countryCode: getCountryLabelFromCode(metaData.countryCode)
        }))
        
        // call user data sync function
        initiateDataSync(metaData, syncData);
    }

    // Reseting the value of the input tag to nil
    document.getElementById(userMetadataDataPoint).value = "";
}

// getEncryptedUserMetadataCallback
export const getEncryptedUserMetadataCallback = () => {
    console.log("getEncryptedUserMetadataCallback Called")
    const { dispatch } = store;
    let value = document.getElementById(encryptedUserMetadataDataPoint).value;
    console.log("value revieved from client", value);
    if (value) {
        dispatch(setEncryptedUserMetadataData(value));
    }
    // Resting the value of the input tag to nil
    document.getElementById(encryptedUserMetadataDataPoint).value = "";
}

// getEncryptedRegisterTasksCallback
export const getEncryptedRegisterTasksCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(encryptedRegisterTasksDataPoint).value;
    if (value) {
        dispatch(setEncryptedRegisterTasksData(value));
    }
    // Resting the value of the input tag to nil
    document.getElementById(encryptedRegisterTasksDataPoint).value = "";
}

// getEncryptedRedeemRewardsCallback
export const getEncryptedRedeemRewardsCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(encryptedRedeemRewardsDataPoint).value;
    if (value) {
        dispatch(setEncryptedRedeemRewardsData(value));
    }
    // Resting the value of the input tag to nil
    document.getElementById(encryptedRedeemRewardsDataPoint).value = "";
}

// getUserAuthTokensCallback  (Is not needed any more we are getting all the auth tokens with meta data)
const getUserAuthTokensCallback = () => {
    const { dispatch } = store;

    let value = document.getElementById(authTokenDataPoint).value;
    if (value) {
        let parsedData = parseClientData(value);
        console.log({ parsedData })

        store.dispatch(setUserAuthTokens(parsedData));
    }

    // Reseting the value of the input tag to nil
    document.getElementById(authTokenDataPoint).value = "";
}

// getUserLocationCallback 
const getUserLocationCallback = () => {
    console.log("getUserLocationCallback envoked DEBUG:");
    const { dispatch } = store;
    let value = document.getElementById(userLocationDataPoint).value;
    console.log("value revieved from userLocationDataPoint", value);
    // let value = "state=Uttar Pradesh,city=Ghaziabad,locality=Ghaziabad,pinCode=201014,countryCode=IN,latitude=23,longitude=12"
    // testToast("getUserLocationCallback envoked Toast: " + value, toastTypes.info);
    // showToast("getUserLocationCallback envoked Toast: " + value, toastTypes.success);

    if (value) {
        let parsedData = parseClientData(value);
        console.log(JSON.stringify(parsedData))
        dispatch(setUerLocationFromClient({
            pincode: parsedData.pinCode,
            city: returnValidStringFromRawInput(parsedData.city),
            state: returnValidStringFromRawInput(parsedData.state),
            landmark: returnValidStringFromRawInput(parsedData.locality),
            address: returnValidStringFromRawInput(parsedData.address),
            addressType: returnValidStringFromRawInput(parsedData.addressType),
            addressName: returnValidStringFromRawInput(parsedData.addressName),
            isDefault: parsedData.isDefault,
            countryCode: parsedData.countryCode,
        }));

        dispatch(setLatLong({
            latitude: parsedData.latitude,
            longitude: parsedData.longitude,
        }))

        let eventLabel = {}
        eventLabel["latitude"] = parsedData?.latitude ?? ""
        eventLabel["longitude"] = parsedData?.longitude ?? ""
        eventLabel["city"] = parsedData?.city ?? ""
        eventLabel["state"] = parsedData?.state ?? ""
        eventLabel["country"] = parsedData?.countryCode ?? ""
        eventLabel["status"] = EventStatus.success
        dispatchEventToNative(LocationReceivedEvent, eventLabel)
        dispatch(setGetUserLocationFromClientOnGoing(false))
    }

    // Reseting the value of the input tag to nil
    document.getElementById(userLocationDataPoint).value = "";
}


// getIntentFiltersCallback 
const getIntentFiltersCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(intentFiltersDataPoint).value;
    console.log("Intent Filters element :: ", value)

    if (value) {
        let parseIntents = JSON.parse(value);
        console.log("Intent Filters:: ", JSON.stringify(parseIntents))
    }

    // Reseting the value of the input tag to nil
    document.getElementById(intentFiltersDataPoint).value = "";
}

// getInstalledAppsCallback 
const getInstalledAppsCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(installedAppsDataPoint).value;
    console.log("getInstalledAppsCallback :: ", value)
    // value = "com.jio.jioplay.tv, com.google.android.youtube, com.samsung.android.app.galaxyfinder, com.samsung.android.themestore, com.smallcase.android, uttarpradesh.citizen.app, com.sec.location.nfwlocationprivacy, naukriApp.appModules.login, com.truecaller, sinet.startup.inDriver, com.google.android.googlequicksearchbox, com.samsung.android.calendar, com.sbi.lotusintouch, com.whereismytrain.android, com.nextbillion.groww, com.phonepe.app, com.samsung.ecomm.global.in, com.delivery.india.client, com.cowinapp.app, com.dunzo.user, com.oyerickshaw.oyerider, com.wework.mobile, com.documentreader.documentapp.filereader"

    if (value) {
        // let installedApps = JSON.parse(value);
        // console.log("getInstalledAppsCallback:: ", installedApps)
        let val = value.split(",")
        console.log("getInstalledAppsCallbackArray:: ", val)
        dispatch(setInstalledApps(val))
    }

    // Reseting the value of the input tag to nil
    document.getElementById(intentFiltersDataPoint).value = "";
}


// shareProductCallback 
export const shareProductCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(shareProductDataPoint).value;
    console.log("shareProductCallback :: ", value)

    if (value) {
        console.log("shareProductCallbackReceived :: ", value)
        dispatch(setProductSharingState({ isProductSharingActive: false, shareProductId: null }))
    }

    // Reseting the value of the input tag to nil
    document.getElementById(shareProductDataPoint).value = "";
}
export const isPackagesInstalledCallback = () => {
    console.log("isPackagesInstalledCallback envoked DEBUG:");
    const { dispatch } = store;
    let value = document.getElementById(isPackagesInstalledDataPoint)?.value;
    console.log("isPackagesInstalledCallback :: ", value)

    dispatch(setIsPackageInstalled(value))

    // Reseting the value of the input tag to nil
    document.getElementById(isPackagesInstalledDataPoint).value = "";
}


export const superAppCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(superAppDataPoint).value;
    console.log("superAppCallback :: ", value)

    if (value) {
        console.log("superAppCallbackRecved :: ", value)
    }

    // Reseting the value of the input tag to nil
    document.getElementById(superAppDataPoint).value = "";
}

export const refreshRewardsCallback = () => {
    const { dispatch } = store;
    let value = document.getElementById(refreshRewardsDataPoint).value;
    console.log("refreshRewardsCallback :: ", value)
    // unix time string
    value = new Date().toString()
    console.log("refreshRewardsCallback :: ", value)
    if (value) {
        console.log("refreshRewardsCallbackReceived :: ", value)
        dispatch(setRefreshRewardsHomeData(value))
    }

    // Reseting the value of the input tag to nil
    document.getElementById(refreshRewardsDataPoint).value = "";
}

export {
    invokeTruecallerLoginCallback,
    getUserMetaDataCallback,
    getUserAuthTokensCallback,
    getUserLocationCallback,
    getIntentFiltersCallback,
    getInstalledAppsCallback,
    invokeTruecallerLoginRewardSystemCallback,
    invokeLoginActivityCallback,
    getUserMetaDataForRewardsCallback,
}
