export const supportedCodeLength = 6
export const otpAutoDetectionSource = "google_sms_retriever_api"
export const loginType = "sms"
export const grantType = "password"
export const grantTypeRefreshToken = "refresh_token"
export const maxNumberOfTries = 5
export const retryDuration = 5000
export const maxNumberOfTriesForCartSync = 3
export const retryDurationForCartSync = 2500
export const maxNumberOfTriesForWishlistSync = 3
export const retryDurationForWishlistSync = 2500
export const maxNumberOfTriesForAddressSync = 3
export const retryDurationForAddressSync = 2500
export const INVALID_DPANDA_ACCESS_TOKEN = "invalidDpandaAccessToken"
export const INVALID_ACCESS_TOKEN = "invalidAccessToken"
export const INVALID_REFRESH_TOKEN = "invalidRefreshToken"
