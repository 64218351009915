import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { createWrapper, HYDRATE } from 'next-redux-wrapper'
import cart from './slices/cart'
import header from './slices/header'
import footer from './slices/footer'
import user from './slices/user'
import userAddress from './slices/userAddress'
import wishlist from './slices/wishlist'
import events from './slices/events'
import global from './slices/global'
import storage from './storage'
import { persistReducer } from 'redux-persist'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['cart', 'wishlist', 'user', 'userAddress', 'header']
}

const combinedReducer = combineReducers({
    wishlist,
    cart,
    user,
    userAddress,
    header,
    footer,
    events,
    global
});

const masterReducer = (state, action) => {
    if (action.type === HYDRATE) {
        return {
            ...state, // use previous state
            cart: {
                items: [...action.payload.cart.items, ...state.cart.items]
            },
            wishlist: {
                items: [...action.payload.wishlist.items, ...state.wishlist.items]
            },
            userAddress: {
                items: [...action.payload.userAddress.items, ...state.userAddress.items]
            }
        };
    } else {
        return combinedReducer(state, action)
    }
}

const persistedReducer = persistReducer(persistConfig, masterReducer)

export const makeStore = () =>
    configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
    });


export const store = makeStore();

export const wrapper = createWrapper(makeStore, { debug: true });
