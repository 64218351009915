import React from "react";
import { useRouter } from "next/router";
import { DPANDA_CART_ENDPOINT, DPANDA_HOST, DPANDA_PUB_ID } from "../../config/dpanda";
import { store } from "../../store/store";

export default function AddToCartToast(props) {

    const router = useRouter();
    let state = store?.getState();
    let dpandaAuthtoken = state?.user?.data?.dpandaAuthtoken;

    return (
        <div id="add-to-cart-toast">
            <div className="d-flex justify-content-between align-items-center">
                <div className="toast-text">
                    Item added to cart
                </div>
                <div onClick={() => {
                    router.push(DPANDA_HOST + DPANDA_CART_ENDPOINT + '&session_id=' + dpandaAuthtoken + "&pub_id=" + DPANDA_PUB_ID)
                }}>

                    <span className="button">Go to Cart</span>
                </div>
            </div>
        </div>
    )
}