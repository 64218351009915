
import { host } from "./index"
import axios from 'axios';

// getUserWishListProducts is used to get user wish list
export let getUserWishListProducts = async ({ userId, accessToken }) => {
    let state = await store.getState();
    let deviceId = state?.user?.clientData?.deviceId;
    let url = `${host}/v1/superApps/users/${userId}/wishlist`;
    if (deviceId != null){
        url = url + "?deviceId=" + deviceId
    }

    let request = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)
};

// addProductToWishList is used to update user wish list 
export let addProductToWishList = async ({ userId, productId, variantId, vendorId, accessToken }) => {

    let url = `${host}/v1/superApps/users/${userId}/wishlist`;

    let body = {
        "productId": productId,
        "variantId": variantId,
        "vendorId": vendorId
    }

    let request = {
        method: "POST",
        url: url,
        data: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        },
        body: JSON.stringify(body)
    }

    return axios(request)
};

export let removeProductFromWishList = async (userId, productId, variantId, vendorId, accessToken) => {
    let url = `${host}/v1/superApps/users/${userId}/wishlist`;
    let body = {
        "productId": productId,
        "variantId": variantId,
        "vendorId": vendorId
    }

    let request = {
        method: "DELETE",
        url: url,
        data: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)
}

export let getUserWishList = async (userId, accessToken) => {
    let state = await store.getState();
    let deviceId = state?.user?.clientData?.deviceId;
    if (!userId) return;
    if (!accessToken) return;

    let url = `${host}/v1/superApps/users/${userId}/wishlist?deviceId=${deviceId}`;

    let request = {
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": accessToken,
        }
    }

    return axios(request)
};