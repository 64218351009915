import {CDN_ASSETS_URL} from "./index.js"


const cdnHost = CDN_ASSETS_URL+"/super-apps/webview-icons/"
const rewardsCDNHost = CDN_ASSETS_URL+"/super-apps/rewards/"

const trueCallerIcon = cdnHost + 'truecaller_icon-circle-4x.png';
const circleRightArrowIcon = cdnHost + "expand_circle_right-arrow-black-2x.png"
const useMyLocationIcon = cdnHost + "use_my_location_icon_2x.webp"
const ribbonIcon = cdnHost + "search_container_ribbon_4x.png"
const allCategoriesIcon = cdnHost + "all_categories_icon_2x.png"
const editAddressIcon = cdnHost + "edit_address_icon_2x.webp"
const productShareIcon = cdnHost + "product_share_icon_2x.webp"
const sortIcon = cdnHost + "sort_icon_4x_v3.png"
const filterIcon = cdnHost + "filter_icon_4x_v3.png"
const bobbleIcon = cdnHost + "bobble_icon_1x.png"
const dpandaIcon = cdnHost + "dpanda_logo.webp"
const flipkartIcon = cdnHost + "flipkart_icon_1x.webp"
const historyIcon = cdnHost + "history_icon_4x_v3.png"
const trendingIcon = cdnHost + "trending_icon_4x_v3.png"
const shareIcon = cdnHost + "share_icon_1.5x.webp"
const orderSuccessIcon = cdnHost + "order_success_icon.png"
const bobbleMallRibbonIcon = cdnHost + "bobble_mall_ribbon_4x.webp"
const gpsLocationIcon = cdnHost + "gps_icon_green_1.5x.webp"
const codAvailableIcon2X = cdnHost + "cod_available_icon_2x.webp"
const codUnavailableIcon2X = cdnHost + "cod_unavailable_icon_2x_v2.webp"
const replacementAvailableIcon2X = cdnHost + "replacement_available_icon_2x.webp"
const replacementUnavailableIcon2X = cdnHost + "replacement_unavailable_icon_2x_v2.webp"
const warrantyAvailableIcon2X = cdnHost + "warrenty_available_icon_2x.png"
const warrantyUnavailableIcon2X = cdnHost + "warrenty_unavailable_icon_2x_v2.webp"
const orderFailureLottie = cdnHost + "order_failure_lottie.json"
const orderSuccessLottie = cdnHost + "order_success_lottie.json"
const locationIcon = cdnHost + "location_icon_4x_v3.png"
const cartIcon = cdnHost + "cart_icon_4x_v3.png"
const searchIcon = cdnHost + "search_icon_4x_v3.png"
const wishlistIcon = cdnHost + "wishlist_icon_4x_v3.png"
const backArrowIcon = cdnHost + "back_arrow_4x_v3.png"
const checkCircle = cdnHost + "check_circle_4x_v3.png"
export const addCircleIcon = cdnHost + "add_circle_icon_4x.png"
export const plusIcon = cdnHost + "plus_icon_4x.webp"
export const minusIcon = cdnHost + "minus_icon_4x.webp"
export const shoppingBagBlackIcon = cdnHost + "shopping_bag_black_icon_4x.png"
export const replyBlackIcon = cdnHost + "reply_black_icon_4x.png"
export const locationBlackIcon = cdnHost + "location_on_black_icon_4x.webp"
export const rightArrowIconGrey = cdnHost + "right_arrow_icon_grey_4x.png"
export const filterIconActive = cdnHost + "filter_icon_active_4x.png"
// export const splashIconRight = cdnHost + "splash_icon_right-v2.webp";
// export const splashIconLeft = cdnHost + "splash_icon_left-v2.webp";
// export const splashScreenBobbleLogo = cdnHost + "splash_screen_bobble_logo.webp";
export const newSplashScreenBobbleLogo = cdnHost + "splash_icon_top.webp";
export const newSplashScreenTop = cdnHost + "new_splash_screen_bobble_logo.webp";
export const arrowButtonIconRight1X = cdnHost + "arrow_button_icon_right-1x.svg";
export const bobbleSuperLogo = "https://www.linkpicture.com/q/bobble_super_logo.webp"
export const bobbleSuperLogoIcon = "https://www.linkpicture.com/q/bobble_super_logo_icon.webp"
// export const saleIconSmall = ;
// export const saleIconMedium = ;
export const saleIconLarge = cdnHost + "large-discount.png"
export const discountBadgeIcon = cdnHost + "discount-badge.png"
export const discountTagIcon = cdnHost + "discount-tag.png"
export const addressIcon = cdnHost + "address_location.png"

export const userIcon = cdnHost + "user_icon.png"
export const userProfilePic = cdnHost + "user_profile_pic.png"
export const newOrderTrackingIcon = cdnHost + "order-tracking.png"
export const newLogoutIcon = cdnHost + "logout.png"
export const newAddressIcon = cdnHost + "address.png"
export const newWishlistIcon = cdnHost + "wishlist.png"
export const shoppingBagIcon = cdnHost + "shopping-bags.png"
export const newBlackArrowIcon = cdnHost + "right-chevron.png"
export const newLocationIcon = cdnHost + "location-icon.png"
export const downArrowIcon = cdnHost + "down-chevron.png"
export const offerCaraouselPic1 = cdnHost + "free_delivery-banner.png"
export const offerCaraouselPic2 = cdnHost + "cash_on_delivery-banner.png"
export const offerCaraouselPic3 = cdnHost + "offers-banner.png"
export const moneyGif = cdnHost + "money.gif"
export const freeDeliveryTagGIf = cdnHost + "free-tag.gif"
export const thunderboltGif = cdnHost + "thunder-bolt.gif"
export const locationPrimaryIcon = cdnHost + "location-primary.svg"
export const minusPrimaryIcon = cdnHost + "minus-icon.svg"
export const plusPrimaryIcon = cdnHost + "plus-icon.svg"
export const searchGrayIcon = cdnHost + "search-icon-gray.svg"
export const searchBackButtonIcon = cdnHost + "back-button-icon.svg"
export const sharePrimaryIcon = cdnHost + "share-icon.svg"
export const searchPrimaryIcon = cdnHost + "search-icon.svg"
export const getAmazingDealsBanner = cdnHost + "get-amazing-deals-banner.png"
export const easyOrderTrackingBanner = cdnHost + "easy-order-tracking-banner.png"
export const personalizedExperience = cdnHost + "personalized-experience-banner.png"
export const editPencilIcon = cdnHost + "pencil-icon.svg"
export const greenTickIcon = cdnHost + "green-tick-icon.svg"
export const newGpsLocationIcon = cdnHost + "gps-location-icon.png"

export const whatsappIcon = cdnHost + "whatsapp_icon.svg"
export const instagramIcon = cdnHost + "instagram_icon.png"
export const facebookMessengerIcon = cdnHost + "facebook_icon.png"
export const telegramIcon = cdnHost + "telegram_icon.png"

export const newHomeIconBlack = cdnHost + "home-icon.png"
export const newWorkIconBlack = cdnHost + "work-icon.png"
export const newOtherIconBlack = cdnHost + "order-icon.png"
export const newHomeIconGrey = cdnHost + "home_gray_icon.png"
export const newWorkIconGrey = cdnHost + "work_gray_icon.png"
export const newOtherIconGrey = cdnHost + "other_gray_icon.png"
export const newHomeIconWhite = cdnHost + "home_white_icon.png"
export const newWorkIconWhite = cdnHost + "work_white_icon.png"
export const newOtherIconWhite = cdnHost + "other_white_icon.png"
export const memeChatCategoryIcon = cdnHost + "memechat_category_icon_v2.webp"

// Rewards Icons
export const transactionButtonIcon = rewardsCDNHost + "history_black.png";
export const faqButtonIcon = rewardsCDNHost + "faq_button.png"
export const refreshButtonIcon = rewardsCDNHost + "sync_black.png";
export const backButtonIcon = rewardsCDNHost + "arrow_back_black.png";
export const bobbleCoinIcon = rewardsCDNHost + "coin.png";
export const inviteFriendBannerAsset = rewardsCDNHost + "invite_friends_asset_v2.png";
export const syncBobbleCoinsBanner = rewardsCDNHost + "sync_coins_login_banner.png";
export const redeemRewardBannerAsset = rewardsCDNHost + "redeem_coins_banner_v2.webp";
export const homeScreenHeaderAsset = rewardsCDNHost + "home_screen_header_asset.png";
export const rewardsScreenHeaderAsset = rewardsCDNHost + "rewards_screen_header_asset.png";
export const welcomeBonusAsset = rewardsCDNHost + "welcome_bonus_asset.png";
export const dailyCoinsCollectedIcon = rewardsCDNHost + "daily_coins_collected.png";
export const taskCompletedIcon = rewardsCDNHost + "task_completed.png";
export const taskIncompleteIcon = rewardsCDNHost + "task_incomplete.png";
export const transactionBackButtonIcon = rewardsCDNHost + "transaction_back_button.png";
export const bobbleSuperHoliOfferBanner = rewardsCDNHost + "bobble_super_holi_offer_banner_1.png";
export const bobbleReferralPageBanner = rewardsCDNHost + "referral_screen_banner-v3.webp";

// Todo : Upload the icons to CDN and use the CDN links
export const backArrow = rewardsCDNHost + "arrow_back_black.png";
export const girlRightEyeWink = rewardsCDNHost + "bubbly-right-eye-wink.png";
export const referralBanner = rewardsCDNHost + "remove-watermark-from-bobble-stickers-and-gifs.png";
export const inviteMoreFriendsBanner = rewardsCDNHost + "invite-more-friends.png";
export const giftBoxIcon = rewardsCDNHost + "gift_box.png";
export const copyToClipboardIcon = rewardsCDNHost + "copy_icon.png";

const getVendorLogo = (vendorName) => {
    let vendorLogos = {
        "dpanda": dpandaIcon,
        "flipkart": flipkartIcon,
    }

    if (vendorName && vendorName.length > 0) {
        return vendorLogos[vendorName.toLowerCase()];
    }
}

export {
    cartIcon,
    searchIcon,
    wishlistIcon,
    trueCallerIcon,
    circleRightArrowIcon,
    useMyLocationIcon,
    allCategoriesIcon,
    editAddressIcon,
    productShareIcon,
    sortIcon,
    filterIcon,
    bobbleIcon,
    flipkartIcon,
    historyIcon,
    trendingIcon,
    shareIcon,
    orderSuccessIcon,
    bobbleMallRibbonIcon,
    gpsLocationIcon,
    codAvailableIcon2X,
    codUnavailableIcon2X,
    replacementAvailableIcon2X,
    replacementUnavailableIcon2X,
    warrantyAvailableIcon2X,
    warrantyUnavailableIcon2X,
    getVendorLogo,
    orderFailureLottie,
    orderSuccessLottie,
    dpandaIcon,
    locationIcon,
    backArrowIcon,
    ribbonIcon,
    checkCircle
}