import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserWishListProducts } from '../../util/apiUtils/wishlist';
import { fetchWithRetry } from '../../util/dataSync'

export const fetchWishListItems = createAsyncThunk('wishlist/fetchWishListItems', async ({ userId, accessToken }) => {
    let payload = { accessToken, userId };
    let res = await fetchWithRetry(getUserWishListProducts, payload);
    return res?.data?.products;
})

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        items: [],
        totalWishlistItems: 0,
        loading: false,
        error: null,
    },
    reducers: {
        addToWishlist: (state, action) => {
            let newItem = action.payload;
            let isItemPresent = state?.items?.find(item => item?.data?.productId == newItem?.data?.productId && item?.variants?.[0]?.id == newItem?.variants?.[0]?.id);
            if (!isItemPresent) {
                state.items.push(newItem);
                state.totalWishlistItems = state.items.length
            }
        },
        removeFromWishlist: (state, action) => {
            state.items = state.items.filter(item => !(item?.data?.productId == action.payload?.data?.productId && item?.variants?.[0]?.id == action.payload?.variants?.[0]?.id));
            state.totalWishlistItems = state.items.length
        },
        getWishlistTotal: (state, action) => {
            state.totalWishlistItems = state.items.length;
        },

        emptyWishlist: (state, action) => {
            state.items = [];
            state.totalWishlistItems = 0;
        }

    },
    extraReducers: {
        [fetchWishListItems.pending]: (state, action) => {
            state.loading = true;
        },
        [fetchWishListItems.fulfilled]: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.items = action.payload;
                state.totalWishlistItems = action.payload?.length;
                state.loading = false;
                state.error = null;
            }
        },
        [fetchWishListItems.rejected]: (state, action) => {
            state.error = action.error;
        },
    }
});

export const { addToWishlist, emptyWishlist, removeFromWishlist, getWishlistTotal } = wishlistSlice.actions;

export default wishlistSlice.reducer;
