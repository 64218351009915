export const regions = [
    {
        "id": "1681",
        "country_code": "IN",
        "iso_code": "AN",
        "name": "Andaman and Nicobar Islands",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1682",
        "country_code": "IN",
        "iso_code": "AP",
        "name": "Andhra Pradesh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1683",
        "country_code": "IN",
        "iso_code": "AS",
        "name": "Assam",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1684",
        "country_code": "IN",
        "iso_code": "CH",
        "name": "Chandigarh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1685",
        "country_code": "IN",
        "iso_code": "DN",
        "name": "Dadra and Nagar Haveli",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1686",
        "country_code": "IN",
        "iso_code": "DL",
        "name": "Delhi",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1687",
        "country_code": "IN",
        "iso_code": "GJ",
        "name": "Gujarat",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1688",
        "country_code": "IN",
        "iso_code": "HR",
        "name": "Haryana",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1689",
        "country_code": "IN",
        "iso_code": "HP",
        "name": "Himachal Pradesh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1690",
        "country_code": "IN",
        "iso_code": "JK",
        "name": "Jammu and Kashmir",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1691",
        "country_code": "IN",
        "iso_code": "KL",
        "name": "Kerala",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1692",
        "country_code": "IN",
        "iso_code": "LD",
        "name": "Lakshadweep",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1693",
        "country_code": "IN",
        "iso_code": "MH",
        "name": "Maharashtra",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1694",
        "country_code": "IN",
        "iso_code": "MN",
        "name": "Manipur",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1695",
        "country_code": "IN",
        "iso_code": "ML",
        "name": "Meghalaya",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1696",
        "country_code": "IN",
        "iso_code": "KA",
        "name": "Karnataka",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1697",
        "country_code": "IN",
        "iso_code": "NL",
        "name": "Nagaland",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1698",
        "country_code": "IN",
        "iso_code": "OR",
        "name": "Orissa",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1699",
        "country_code": "IN",
        "iso_code": "PY",
        "name": "Pondicherry",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1700",
        "country_code": "IN",
        "iso_code": "PB",
        "name": "Punjab",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1701",
        "country_code": "IN",
        "iso_code": "RJ",
        "name": "Rajasthan",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1702",
        "country_code": "IN",
        "iso_code": "TN",
        "name": "Tamil Nadu",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1703",
        "country_code": "IN",
        "iso_code": "TR",
        "name": "Tripura",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1704",
        "country_code": "IN",
        "iso_code": "WB",
        "name": "West Bengal",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1705",
        "country_code": "IN",
        "iso_code": "SK",
        "name": "Sikkim",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1706",
        "country_code": "IN",
        "iso_code": "AR",
        "name": "Arunachal Pradesh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1707",
        "country_code": "IN",
        "iso_code": "MZ",
        "name": "Mizoram",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1708",
        "country_code": "IN",
        "iso_code": "DD",
        "name": "Daman and Diu",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1709",
        "country_code": "IN",
        "iso_code": "GA",
        "name": "Goa",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1710",
        "country_code": "IN",
        "iso_code": "BR",
        "name": "Bihar",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1711",
        "country_code": "IN",
        "iso_code": "MP",
        "name": "Madhya Pradesh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1712",
        "country_code": "IN",
        "iso_code": "UP",
        "name": "Uttar Pradesh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1713",
        "country_code": "IN",
        "iso_code": "CT",
        "name": "Chhattisgarh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1714",
        "country_code": "IN",
        "iso_code": "JH",
        "name": "Jharkhand",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1715",
        "country_code": "IN",
        "iso_code": "UT",
        "name": "Uttarakhand",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1716",
        "country_code": "IN",
        "iso_code": "TG",
        "name": "Telangana",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "1717",
        "country_code": "IN",
        "iso_code": "LA",
        "name": "Ladakh",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-12 06:24:10"
    },
    {
        "id": "4504",
        "country_code": "IN",
        "iso_code": "GLOBAL",
        "name": "GLOBAL",
        "created_at": "2021-07-08 07:30:00",
        "updated_at": "2021-07-19 07:07:22"
    }
]