import { createSlice } from '@reduxjs/toolkit';


const eventsSlice = createSlice({
    name: 'events',
    initialState: {
        bannerIds: [],
        productIds: [],
        superAppIds: [],
    },
    reducers: {

        addBannerIds: (state, action) => {
            let isItemPresent = state?.bannerIds?.includes(action.payload);
            if (!isItemPresent) {
                state.bannerIds.push(action.payload);
            }
        },

        addProductIds: (state, action) => {
            let isItemPresent = state?.productIds?.includes(action.payload);
            if (!isItemPresent) {
                state.productIds.push(action.payload);
            }
        },

        addSuperAppIds: (state, action) => {
            console.log("addSuperAppIds", action.payload);
            let isItemPresent = state?.superAppIds.includes(action.payload);
            if (!isItemPresent) {
                state.superAppIds.push(action.payload);
            }
        }
    }
});

export const { addBannerIds, addProductIds, addSuperAppIds } = eventsSlice.actions;

export default eventsSlice.reducer;
