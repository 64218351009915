import { createSlice } from '@reduxjs/toolkit';

const footerSlice = createSlice({
  name: 'footer',
  initialState: {
    showBottomFixedBar: true,
    activePopupBar: 'cart'
  },
  reducers: {
    setShowBottomFixedBar: (state, action) => {
      state.showBottomFixedBar = action.payload;
    },
    setActivePopupBar: (state, action) => {
      state.activePopupBar = action.payload;
    },
  },
});

export const {
  setShowBottomFixedBar, setActivePopupBar
} = footerSlice.actions;

export default footerSlice.reducer;
