import {
    DpandaDeliveredStatus, DpandaRTODeliveredStatus, DpandaReturnDeliveredStatus,
    DpandaPendingStatus, DpandaCODReceived, DpandaProcessedStatus, DpandaCompleteStatus, DpandaFulfilledStatus, DpandaCancelledStatus, DpandaRefundedStatus, DpandaFailedStatus, DpandaReturnCompletedStatus, DpandaReturnInitiatedStatus
} from "./dpanda";
import getConfig from "next/config";
const { publicRuntimeConfig } = getConfig()
//Environment
export const env = {
    DEV: 'development',
    PROD: 'production'
}

// TODO: change to prod while pushing for release branches
export const ENVIRONMENT = env.PROD; //  "production" | "development" | "test"

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;
const OTP_INPUTS = 6;

// ImpressionEventsDefaultThreshold is the default threshold for impression events tracking
export const ImpressionEventsDefaultThreshold = 0.5

const checkoutSteps = ["Order Summary", "Address", "Payment"];

const Home = "home"
const Office = "office"
const Other = "other"

const Success = "success"
const Failure = "failure"
const Failed = "Failed"

const addressTypes = [Home, Office, Other];

export const trendingSearchTerms = ["Peanut butter", "Earphones", "Face wash", "Body lotion", "Hair serum", "Coffee", "Muesli"]

export const searchHistory = [
    "Mobile",
    "Laptop",
    "Tablet",
]

export const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

// otpResendInterval set at 60 seconds
export const otpResendInterval = 60;

export const otpLength = 6;

export const maxSearchHistoryLength = 5;

export const Flipkart = "flipkart";

export const Dpanda = "dpanda";

export const Bobble = "bobble";
// productSharingTemplate this template is used for sharing product details
//export const productSharingTemplate = "Take a look at this PRODUCT_NAME on VENDOR_NAME \n PRODUCT_URL";
export const productSharingTemplate = "Take a look at this PRODUCT_NAME on Bobble Super🛍🤩 \n PRODUCT_URL";

export const noProductsFoundText = "No products found"
export const recommendedProductsFound = "Oops! Looks like we ran out of ";
export const requestSubmittedText = "Request submitted successfully";

export const LoginSuccessFull = "Login successful";
export const ItemAddedToCartSuccessFull = "Item added to cart successfully";

export { checkoutSteps, addressTypes, Home, Office, Other, Success, Failure, Failed };

export const orderDeliveryStatus = [DpandaDeliveredStatus, DpandaRTODeliveredStatus, DpandaReturnDeliveredStatus, DpandaFulfilledStatus]
export const orderCompleteStatus = [DpandaCompleteStatus, DpandaRefundedStatus, DpandaReturnCompletedStatus]
export const orderCancelledStatus = [DpandaCancelledStatus, DpandaFailedStatus]
export const orderReturnInitaitedStatus = [DpandaReturnInitiatedStatus]
export const orderDeliveryStatusLowerCase = orderDeliveryStatus.map(status => status.toLowerCase())
export const orderCompleteStatusLowerCase = orderCompleteStatus.map(status => status.toLowerCase())
export const orderCancelledStatusLowerCase = orderCancelledStatus.map(status => status.toLowerCase())
export const orderReturnInitaitedStatusLowerCase = orderReturnInitaitedStatus.map(status => status.toLowerCase())

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
export const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sun'];

export const orderSuccessStatus = [Success, DpandaPendingStatus, DpandaProcessedStatus]

export const ProductSharingDeeplink = "https://super.bobble.ai/product.html"

export const WebViewClientId = "IlMuOmXNaZwGc6OcQ16_acwRyddi5VcD"

export const SUPER_APPS_API_HOST = publicRuntimeConfig.NEXT_PUBLIC_SUPER_APPS_API_HOST || "https://api.bobbleapp.me" 

export const CDN_ASSETS_URL=publicRuntimeConfig.NEXT_PUBLIC_CDN_ASSETS_URL || "https://assets.bobblekeyboard.net"

export const orderPlacementWaitTime = 2000;

export { BACKSPACE, LEFT_ARROW, RIGHT_ARROW, DELETE, SPACEBAR, OTP_INPUTS }

export const defaultCountryCode = "91";

export const referralBonusMilestoneThreshold = 25;

export const referralWatermarkRemovalThreshold = 5;

export const referralMilestoneBonus = 500;

export const referralInviteText = "*Share, Earn, Shop!*\n" +
    "Share Stickers, GIFs & BigMojis. Earn Bobble coins & get huge Discounts on Shopping.\n\n" +
    "Download Bobble AI Keyboard & get welcome bonus of *100 Coins*.👇 \n"

export const inviteFriendsMessageShareImages = [CDN_ASSETS_URL+"/super-apps/rewards/invite-friends-message-sticker.gif"]

export const inviteFriendsPackageNames = ["com.whatsapp", "com.facebook.katana", "com.instagram.android"]

export const falllbackInviteLink = "https://makemybobble.in/welcome"

// set this to true if needed to enable explore categories section on homepage
export const isExploreCategoriesEnable = true