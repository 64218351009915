import Router from "next/router";
import { env } from "../../config";
import {
    maxNumberOfTries, maxNumberOfTriesForCartSync,
    maxNumberOfTriesForWishlistSync, retryDuration, retryDurationForCartSync,
    retryDurationForWishlistSync, maxNumberOfTriesForAddressSync, retryDurationForAddressSync
} from "../../config/login";
import { fetchCarts, setCartLoading } from "../../store/slices/cart";
import {
    resetUser,
    setDpandaAuthToken, setGeneratedUserId, setUserAuthData, setUserData, setUserId
} from "../../store/slices/user";
import { fetchAddresses, setSelectedAddress } from "../../store/slices/userAddress";
import { fetchWishListItems } from "../../store/slices/wishlist";
import { store } from "../../store/store";
import { addProductToCart } from "../apiUtils/cart";
import {
    dpandaLogin, getUserMetaData,
    regenerateAccessTokenWithRetry,
    registerWithDeviceID
} from "../apiUtils/login";
import { addProductToWishList } from "../apiUtils/wishlist";
import { convertUTCToEPOCHTime, validateAccessToken, valiDateDpandaAuthToken, getCountryTelephoneCode } from "../common";
import { showToast, toastTypes, testToast } from "../common/toasts";
import { noNetworkErrorCode, noNetworkErrorText, sessionExpiryText, unexpectedErrorText } from "../errors";
import { sendAuthTokensToClient } from "../jsInterfaces";
import { SuperAppErrorOccured, dispatchEventToNative, ActionsTypes, ServiceTypes } from "../events"
import { saveUserAddress } from '../apiUtils/user-address';
import { setIsDeviceRegistered } from "../../store/slices/user";

export const fetchWithRetry = (
    callback,
    payload,
    times = 3,
    duration = 2500
) => {
    let numberOfTries = 0;

    return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            try {
                let response = await callback(payload);
                clearInterval(interval);
                console.log(`Operation successful, retried ${numberOfTries} times.`);
                return resolve(response);
            } catch (err) {
                numberOfTries++;
                console.log(`Unsuccessful, retried ${numberOfTries} times... ${err}`);
                if (numberOfTries === times || err?.response?.status == 401) {
                    console.log(`Trying for the last time... (${times})`);
                    clearInterval(interval);
                    return reject(err);
                }
            }
        }, duration);
    });
};

export let uploadCartToServer = async (
    userId,
    accessToken,
    dpandaAuthtoken
) => {
    const { dispatch } = store;
    let carts = store.getState()?.cart?.carts;
    console.log("STEP 2:: uploadCartToServer called", carts);
    if (!(carts?.length > 0)) {
        dispatch(fetchCarts({ userId, accessToken, dpandaAuthtoken }));
        return;
    }

    for (let cart of carts) {
        for (let product of cart?.products) {
            // check if products in the cart are already synced or not. (if a product is already synced, it won't have the key synced. Only the products not synced would have this key with a false value)
            if (!(product?.synced == false)) {
                continue;
            }

            let payload = {
                userId,
                item: product,
                accessToken,
                dpandaAuthtoken,
                incrementBy: product?.cartQuantity,
            };

            console.log(" STEP 3 uploadCartToServer payload", payload);
            try {
                let response = await fetchWithRetry(
                    addProductToCart,
                    payload,
                    maxNumberOfTriesForCartSync,
                    retryDurationForCartSync
                );
                console.log(response);
                console.log("STEP 4 :: Product added to cart");
                showToast("Product added to cart", toastTypes.success, env.DEV);
            } catch (err) {
                console.log(err);
                console.log("STEP 4 error :: Error while uploading cart items to server");
                showToast("Error while uploading cart items to server", toastTypes.error, env.DEV);
                let eventLabel = {}
                eventLabel["service_type"] = ServiceTypes.productListing
                eventLabel["action_type"] = ActionsTypes.addToCart;
                eventLabel["error_message"] = err?.response?.data?.errorDescription
                eventLabel["error_code"] = err.response?.data?.errorCode
                dispatchEventToNative(SuperAppErrorOccured, eventLabel);
            }
        }
    }
    console.log("STEP 115 :: uploadCartToServer completed");
    store.dispatch(fetchCarts({ userId, accessToken, dpandaAuthtoken }));
    console.log("STEP 116 :: fetchCarts completed");
    // return response;
};

export let uploadWishListToServer = async (userId, accessToken) => {
    const { dispatch } = store;
    let wishListItems = store.getState()?.wishlist?.items;
    console.log(" STEP 5:: uploadWishListToServer called", wishListItems);
    if (!(wishListItems?.length > 0)) {
        dispatch(fetchWishListItems({ userId, accessToken }));
        return;
    }

    for (let item of wishListItems) {
        let payload = {
            userId: userId,
            productId: item?.data?.productId,
            variantId: item?.variants?.[0]?.id,
            vendorId: item?.vendor?.id,
            accessToken: accessToken,
        };

        console.log(" item?.synced ", item?.synced);
        if (!(item?.synced == false)) {
            continue;
        }

        try {
            let response = await fetchWithRetry(
                addProductToWishList,
                payload,
                maxNumberOfTriesForWishlistSync,
                retryDurationForWishlistSync
            );
            console.log(response);
            console.log(" STEP 6:: Product added to wishlist");
            showToast("Product added to wishlist", toastTypes.success, env.DEV);
        } catch (err) {
            console.log(err);
            console.log("Error while uploading wishlist items to server");
            showToast("Error while uploading wishlist items to server", toastTypes.error, env.DEV);
            let eventLabel = {}
            eventLabel["service_type"] = ServiceTypes.productListing
            eventLabel["action_type"] = ActionsTypes.addToWishlist;
            eventLabel["error_message"] = err?.response?.data?.errorDescription
            eventLabel["error_code"] = err.response?.data?.errorCode
            dispatchEventToNative(SuperAppErrorOccured, eventLabel);
        }
    }
    console.log(" STEP 7:: uploadWishListToServer completed");
    dispatch(fetchWishListItems({ userId, accessToken }));
    console.log(" STEP 8:: fetchWishListItems completed");
};

export let uploadAddressToServer = async (userId, accessToken, deviceId) => {
    const { dispatch } = store;
    let addresses = store.getState()?.userAddress?.userAddresses;
    let selectedAddress = store.getState()?.userAddress?.selectedAddress;
    console.log("STEP 7:: uploadAddressToServer called", addresses);
    console.log("isArray:: ", Array.isArray(addresses))
    if (!addresses || !Array.isArray(addresses)) {
        dispatch(fetchAddresses({ accessToken, deviceId }));
        return
    };

    for (let address of addresses) {
        console.log(" Address to be synced", address);
        if (!address?.hasToSync) {
            continue;
        }

        let payload = {
            userId: userId,
            address: address,
            accessToken: accessToken,
            deviceId: deviceId
        };

        try {
            let response = await fetchWithRetry(
                saveUserAddress,
                payload,
                maxNumberOfTriesForAddressSync,
                retryDurationForAddressSync
            );
            console.log(response);

            if (address?.id === selectedAddress?.id) {
                dispatch(setSelectedAddress({ ...address, id: response?.data?.id }))
            }

            console.log(" STEP 8:: Address added to server");
            showToast("Address added to server", toastTypes.success, env.DEV);
        } catch (err) {
            console.log(err);
            console.log("Error while uploading address to server");
            showToast("Error while uploading address to server", toastTypes.error, env.DEV);
            let eventLabel = {}
            eventLabel["service_type"] = ServiceTypes.productListing
            eventLabel["action_type"] = ActionsTypes.addAddress;
            eventLabel["error_message"] = err?.response?.data?.errorDescription
            eventLabel["error_code"] = err.response?.data?.errorCode
            dispatchEventToNative(SuperAppErrorOccured, eventLabel);
        }
    }

    console.log("STEP 9:: uploadAddressToServer completed");
    dispatch(fetchAddresses({ accessToken, deviceId }));
    console.log("STEP 10:: fetchAddresses completed");
}

export const userDataSync = async (userId, accessToken, dpandaAuthtoken, deviceId) => {
    if (!userId) return;
    const { dispatch } = store;

    console.log("userDataSync", userId, accessToken);
    // upload cart and wishlist items to the server
    console.log("STEP 1:: uploading cart and wishlist items to the server");
    await uploadCartToServer(userId, accessToken, dpandaAuthtoken);

    await uploadAddressToServer(userId, accessToken, deviceId);
    if (!accessToken) return
    await uploadWishListToServer(userId, accessToken);
    showToast("Debug. Cart and wishlist items uploaded to server", toastTypes.success, env.DEV);
    console.log("STEP 7:: Debug. Cart and wishlist items uploaded to server");
    // sync user data from server
    showToast("User data synced from server.", toastTypes.success, env.DEV);
    console.log("STEP 8:: Debug. User data synced from server.");
};

// Rename the function
export const initiateDataSync = async (metaData, syncUserData = true) => {
    console.log("datasync Called");
    const { dispatch } = store;
    dispatch(setCartLoading(true))
    let {
        accessToken,
        refreshToken,
        expiresIn,
        deviceId,
        clientId,
        clientSecret,
        deviceType,
        sdkVersion,
        appVersion,
        phoneNumber,
    } = metaData;
    let userId, dpandaAuthtoken, generatedUserId;

    // if access token is empty then do not call data sync ( can retry fetching the user meta data from client)
    if ((!accessToken || accessToken === "") && (!deviceId || deviceId === "")) {
        console.log("access token is empty");
        return false;
    }


    let state = store?.getState();
    dpandaAuthtoken = state?.user?.data?.dpandaAuthtoken;
    let dpandaAuthTokenExpiry = state?.user?.data?.dpandaAuthTokenExpiry;
    generatedUserId = state?.user?.data?.generatedUserId;

    if ((!generatedUserId || generatedUserId === "") && (!accessToken || accessToken === "") && syncUserData) {
        dispatch(resetUser());
    }

    if ((!generatedUserId || generatedUserId === "") && (deviceId && deviceId !== "")) {
        try {
            let payload = { deviceId };
            let userIDLogin = await fetchWithRetry(
                registerWithDeviceID,
                payload,
                maxNumberOfTries,
                retryDuration
            );
            console.log("userIDLogin", userIDLogin?.data?.generatedUserId);
            if (userIDLogin?.data) {
                if(accessToken === "" || accessToken?.length === 0){
                    generatedUserId = userIDLogin?.data?.generatedUserId;
                    userId = userIDLogin?.data?.userId;
                    console.log("generatedUserId", generatedUserId, userId);
                    dispatch(setGeneratedUserId(userIDLogin?.data?.generatedUserId))
                    dispatch(setUserId(userIDLogin?.data?.userId))
                }   
                dispatch(setIsDeviceRegistered(true))
            }
        } catch (err) {
            console.log("Error in register with deviceID", err);

            err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
                showToast(unexpectedErrorText, toastTypes.error);
        }
    }

    console.log("dpandaAuthtoken from state", dpandaAuthtoken);
    console.log("dpandaAuthTokenExpiry from state", dpandaAuthTokenExpiry);
    // showToast("Debug. dpandaAuthtoken from state", toastTypes.info);
    // showToast("Debug. dpandaAuthTokenExpiry from state", toastTypes.info);
    if (!valiDateDpandaAuthToken(dpandaAuthtoken, dpandaAuthTokenExpiry)) {
        // showToast("Debug. dpandaAuthtoken expired. Regenerating it.", toastTypes.info);
        try {
            let payload = { phoneNumber, generatedUserId };
            let vendorLogin = await fetchWithRetry(
                dpandaLogin,
                payload,
                maxNumberOfTries,
                retryDuration
            );
            console.log("vendorLogin", vendorLogin);
            if (vendorLogin?.data?.accessToken) {
                console.log("Setting dpanda access token to state");

                dpandaAuthtoken = vendorLogin.data.accessToken;
                dpandaAuthTokenExpiry = convertUTCToEPOCHTime(vendorLogin.data.expiry);
                console.log("dpandaAuthTokenExpiry after epoch conversion", dpandaAuthTokenExpiry);

                // showToast(`Debug. dpandaAuthtoken fetched from server ${dpandaAuthtoken} ${dpandaAuthTokenExpiry}`, toastTypes.info);
                dispatch(setDpandaAuthToken({ dpandaAuthtoken, dpandaAuthTokenExpiry }));

            }
        } catch (err) {
            console.log("Error in dpanda login API", err);

            err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
                showToast(unexpectedErrorText, toastTypes.error);
            let eventLabel = {}
            eventLabel["service_type"] = ServiceTypes.productListing
            eventLabel["action_type"] = ActionsTypes.vendorLogin;
            eventLabel["error_message"] = err?.response?.data?.errorDescription
            eventLabel["error_code"] = err.response?.data?.errorCode
            dispatchEventToNative(SuperAppErrorOccured, eventLabel);
        }

    }

    if (phoneNumber && phoneNumber !== "" && phoneNumber?.length === 10 && (!generatedUserId || generatedUserId === "")) {
        let accessTokenIsValid = validateAccessToken(accessToken, expiresIn);

        console.log("accessTokenIsValid", accessTokenIsValid);
        // showToast(`Debug. accessTokenIsValid ${accessTokenIsValid}`, toastTypes.info);
        if (accessTokenIsValid) {
            console.log("access token is valid");
            // fetch user metadata and addresses from user API

            try {
                let payload = { accessToken, phoneNumber };
                let response = await fetchWithRetry(
                    getUserMetaData,
                    payload,
                    maxNumberOfTries,
                    retryDuration
                );
                console.log(
                    "getUserMetaData FROM API response: ",
                    JSON.stringify(response?.data)
                );
                if (response?.data?.userId) {
                    dispatch(setUserData({ ...response?.data, dpandaAuthtoken }));
                    userId = response.data.userId;
                } else if (response?.status == 200 && response?.data?.userId == 0) {
                    console.log("User not found");
                    showToast(sessionExpiryText, toastTypes.error);
                    setTimeout(() => {
                        Router.push("/bobblexclusive/login");
                    }, 2000)
                    return false;
                }
            } catch (err) {
                console.log("Error in fetching user meta data", err);
                showToast(unexpectedErrorText, toastTypes.error, env.DEV);

                // if access token is expired, then regenerate access token and try again
                if (err?.response?.status === 401) {
                    accessTokenIsValid = false;
                }

                let eventLabel = {}
                eventLabel["service_type"] = ServiceTypes.productListing
                eventLabel["action_type"] = ActionsTypes.getUserMetaData;
                eventLabel["error_message"] = err?.response?.data?.errorDescription
                eventLabel["error_code"] = err.response?.data?.errorCode
                dispatchEventToNative(SuperAppErrorOccured, eventLabel);
            }
        }

        // if access token is not valid then refresh token
        if (!accessTokenIsValid) {
            console.log("access token is not valid");
            let payload = {
                refreshToken,
                deviceId,
                clientId,
                clientSecret,
                deviceType,
                appVersion,
                sdkVersion,
            };
            try {
                let refreshedTokens = await fetchWithRetry(
                    regenerateAccessTokenWithRetry,
                    payload
                );
                if (refreshedTokens?.data?.access_token) {
                    accessToken = refreshedTokens?.data?.access_token;
                    dispatch(
                        setUserAuthData({
                            accessToken: refreshedTokens?.data?.access_token,
                            refreshToken: refreshedTokens?.data?.refresh_token,
                            expiresIn: refreshedTokens?.data?.expires_in,
                            tokenType: refreshedTokens?.data?.token_type,
                            phoneNumber: phoneNumber,
                            countryCode: getCountryTelephoneCode(state?.user?.authData?.countryCode)
                        })
                    );

                    let payload = { accessToken, phoneNumber };
                    let response = await fetchWithRetry(
                        getUserMetaData,
                        payload,
                        maxNumberOfTries,
                        retryDuration
                    );
                    console.log(
                        "getUserMetaData FROM API response",
                        JSON.stringify(response?.data)
                    );
                    if (response?.data?.userId) {
                        dispatch(setUserData(response?.data));
                        userId = response.data.userId;
                    } else if (response?.status == 200 && response?.data?.userId == 0) {
                        console.log("User not found");
                        showToast(sessionExpiryText, toastTypes.error);
                        setTimeout(() => {
                            Router.push("/bobblexclusive/login");
                        }, 2000)
                        return false;
                    }

                    // send data back to client, make payload for it
                    sendAuthTokensToClient({
                        accessToken: refreshedTokens?.data?.access_token,
                        refreshToken: refreshedTokens?.data?.refresh_token,
                        expiresIn: refreshedTokens?.data?.expires_in,
                        tokenType: refreshedTokens?.data?.token_type,
                        phoneNumber: phoneNumber,
                        countryCode: getCountryTelephoneCode(state?.user?.authData?.countryCode)
                    });
                } else {
                    showToast(sessionExpiryText, toastTypes.error);
                    setTimeout(() => {
                        Router.push("/bobblexclusive/login");
                    }, 2000)
                    return false;
                }
            } catch (err) {
                console.log("Error in regenerateAccessTokenWithRetry", err);

                err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
                    showToast(sessionExpiryText, toastTypes.error);
                let eventLabel = {}
                eventLabel["service_type"] = ServiceTypes.productListing
                eventLabel["action_type"] = ActionsTypes.regenerateTokens;
                eventLabel["error_message"] = err?.response?.data?.errorDescription
                eventLabel["error_code"] = err.response?.data?.errorCode
                dispatchEventToNative(SuperAppErrorOccured, eventLabel);
                setTimeout(() => {
                    Router.push("/bobblexclusive/login");
                }, 2000)
                return false;
            }
        }
    }

    // calling this method will sync the user data on server with the client
    if (syncUserData) {
        userDataSync(userId, accessToken, dpandaAuthtoken, deviceId);
    }

    console.log("data sync completed");
    return true;
};

export let regenerateTokens = async (metaData) => {
    let { dispatch } = store;
    let countryCode = store.getState().user?.authData?.countryCode;
    console.log("regenerateTokens Called");
    let {
        refreshToken,
        deviceId,
        clientId,
        clientSecret,
        deviceType,
        appVersion,
        sdkVersion,
        accessToken,
        phoneNumber,
    } = metaData;
    console.log("access token is not valid in regenerateTokens");
    let userId;
    let payload = {
        refreshToken,
        deviceId,
        clientId,
        clientSecret,
        deviceType,
        appVersion,
        sdkVersion,
        accessToken,
        userId,
    };

    try {
        let refreshedTokens = await fetchWithRetry(
            regenerateAccessTokenWithRetry,
            payload
        );
        if (refreshedTokens?.data?.access_token) {
            accessToken = refreshedTokens?.data?.access_token;
            dispatch(
                setUserAuthData({
                    accessToken: refreshedTokens?.data?.access_token,
                    refreshToken: refreshedTokens?.data?.refresh_token,
                    expiresIn: refreshedTokens?.data?.expires_in,
                    tokenType: refreshedTokens?.data?.token_type,
                    phoneNumber: phoneNumber,
                    countryCode: getCountryTelephoneCode(countryCode)
                })
            );

            let payload = { accessToken, phoneNumber };
            let response = await fetchWithRetry(
                getUserMetaData,
                payload,
                maxNumberOfTries,
                retryDuration
            );

            console.log(
                "getUserMetaData FROM API response",
                JSON.stringify(response?.data)
            );

            if (response?.data?.userId) {
                dispatch(setUserData(response?.data));
                // userId = response.data.userId;
            } else if (response?.status == 200 && response?.data?.userId == 0) {
                console.log("User not found");
                showToast(sessionExpiryText, toastTypes.error);
                setTimeout(() => {
                    Router.push("/bobblexclusive/login");
                }, 2000)
                return;
            }

            // send data back to client, make payload for it
            sendAuthTokensToClient({
                accessToken: refreshedTokens?.data?.access_token,
                refreshToken: refreshedTokens?.data?.refresh_token,
                expiresIn: refreshedTokens?.data?.expires_in,
                tokenType: refreshedTokens?.data?.token_type,
                phoneNumber: phoneNumber,
                countryCode: getCountryTelephoneCode(countryCode)
            });
        } else {
            showToast(sessionExpiryText, toastTypes.error);
            setTimeout(() => {
                // TODO: remove params after testing
                Router.push("/bobblexclusive/login");
            }, 2000)
            return;
        }
    } catch (err) {
        console.log("Error in regenerateAccessTokenWithRetry", err);

        err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
            showToast(sessionExpiryText, toastTypes.error);
        let eventLabel = {}
        eventLabel["service_type"] = ServiceTypes.productListing
        eventLabel["action_type"] = ActionsTypes.regenerateTokens;
        eventLabel["error_message"] = err?.response?.data?.errorDescription
        eventLabel["error_code"] = err.response?.data?.errorCode
        dispatchEventToNative(SuperAppErrorOccured, eventLabel);
        setTimeout(() => {
            Router.push("/bobblexclusive/login");
        }, 2000)
        return;
    }

    console.log("regenerateTokens Finished");
};

export let generateDpandaAuthtoken = async (phoneNumber, generatedUserId) => {
    console.log("generateDpandaAuthtoken Called");
    let { dispatch } = store;
    let dpandaAuthtoken;
    try {
        let payload = { phoneNumber, generatedUserId };
        let vendorLogin = await fetchWithRetry(
            dpandaLogin,
            payload,
            maxNumberOfTries,
            retryDuration
        );
        console.log("vendorLogin", vendorLogin);

        if (vendorLogin?.data?.accessToken) {
            showToast("Logged in to Dpanda successfully", toastTypes.success, env.DEV);
            dpandaAuthtoken = vendorLogin.data.accessToken;
            let dpandaAuthTokenExpiry = convertUTCToEPOCHTime(vendorLogin.data.expiry);
            console.log("dpandaAuthTokenExpiry after epoch conversion 1", dpandaAuthTokenExpiry);
            dispatch(setDpandaAuthToken({ dpandaAuthtoken, dpandaAuthTokenExpiry }));
        }
    } catch (err) {
        console.log("Error in dpanda login API", err);
        showToast("Error in dpanda login API", toastTypes.error, env.DEV);
        let eventLabel = {}
        eventLabel["service_type"] = ServiceTypes.productListing
        eventLabel["action_type"] = ActionsTypes.vendorLogin;
        eventLabel["error_message"] = err?.response?.data?.errorDescription
        eventLabel["error_code"] = err.response?.data?.errorCode
        dispatchEventToNative(SuperAppErrorOccured, eventLabel);

        err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
            showToast(unexpectedErrorText, toastTypes.error);
    }
    console.log("generateDpandaAuthtoken Finished");
    return dpandaAuthtoken;
};


export let fetchGeneratedUserId = async (deviceId) => {
    console.log("fetch generatedUserId Called");
    let { dispatch } = store;
    let userInfo = {};
    try {
        let payload = { deviceId };
        let userIDLogin = await fetchWithRetry(
            registerWithDeviceID,
            payload,
            maxNumberOfTries,
            retryDuration
        );

        console.log("userIDLogin", userIDLogin?.data);
        console.log("userIDDetaisl", userIDLogin?.data?.userId, userIDLogin?.data?.generatedUserId)

        if (userIDLogin?.data?.generatedUserId) {
            userInfo.userId = userIDLogin?.data?.userId;
            userInfo.generatedUserId = userIDLogin?.data?.generatedUserId;
            dispatch(setGeneratedUserId(userIDLogin?.data?.generatedUserId))
            dispatch(setUserId(userIDLogin?.data?.userId))
        }
    } catch (err) {
        console.log("Error in login with device ID", err);
        showToast("Error in login with device ID", toastTypes.error, env.DEV);

        err?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
            showToast(unexpectedErrorText, toastTypes.error);
    }
    console.log("generateDpandaAuthtoken Finished");
    return userInfo;
};