import axios from "axios";
import { SUPER_APPS_API_HOST } from "../../config";

export const host = SUPER_APPS_API_HOST

// Language: javascript
export const fetchSuperAppsList = async (deviceId, clientId, appVersion) => {

    let request = {
        method: 'get',
        url: `${host}/v1/superApps/list?deviceId=${deviceId}&clientId=${clientId}&appVersion=${appVersion}`,
    };

    return axios(request);
}

export const fetchWithRetry = (callback, payload, times = 3) => {
    let numberOfTries = 0;

    return new Promise((resolve, reject) => {
        const interval = setInterval(async () => {
            numberOfTries++;
            if (numberOfTries === times) {
                console.log(`Trying for the last time... (${times})`);
                clearInterval(interval);
                return resolve("failure")
            }
            try {
                let response = await callback(payload);
                clearInterval(interval);
                console.log(`Operation successful, retried ${numberOfTries} times.`);
                return resolve(response)
            } catch (err) {
                console.log(`Unsuccessful, retried ${numberOfTries} times... ${err}`);
            }
        }, 2500);
    });
};
