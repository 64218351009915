import dynamic from 'next/dynamic';
import React from "react";
import { unexpectedErrorAsset } from "../config/assets";
import { backArrowIcon } from "../config/icons";
const ErrorMessageComponent = dynamic(() => import("./common/ErrorMessageComponent"))

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo })
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {

      const onBackButtonClick = () => {
        this.setState({ hasError: false }, () => {
          window.location.replace("/bobblexclusive/home")
        })
      }

      return (
        <>
          <div
            id="header"
            className="header-border-bottom"
          >
            <div className="pt-3 pb-2 ps-2 pe-2 header-body">
              <div onClick={onBackButtonClick}>
                {/* <i className="bi bi-chevron-left px-1 left-arrow-icon" /> */}
                <img
                  src={backArrowIcon}
                  alt="back-icon"
                  className="back-arrow-icon"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="error-container">
            <div className='error-message-container'>
              <ErrorMessageComponent
                errorMessage={'Oops! Something went wrong'}
                image={unexpectedErrorAsset}
              />
              <a className='continue-shopping-button text-selection-none ' onClick={() => this.setState({ hasError: false })}>
                <span>Retry</span>
              </a>
            </div>
          </div>
        </>
      )
    }

    // Return children components in case of no error

    return this.props.children
  }
}

export default ErrorBoundary