export const unexpectedErrorText = "Oops!! Something went wrong. Please try again later."
export const sessionExpiryText = "Your session has expired. Please login again."
export const invalidDpandaAccessTokenErrorText = "Invalid Dpanda access token. Regenerating Again."
export const invalidFlipkartAccessTokenErrorText = "Invalid Flipkart access token. Regenerating Again."
export const addAddressFailedText = "Failed to add address. Please try again later."
export const updateAddressFailedText = "Failed to update address. Please try again later."
export const addToCartFailedText = "Failed to add item to cart. Please try again later."
export const removeFromCartFailedText = "Failed to remove item from cart. Please try again later."
export const updateCartFailedText = "Failed to update cart. Please try again later."
export const getCartFailedText = "Failed to get cart. Please try again later."
export const getOrdersFailedText = "Failed to get orders. Please try again later."
export const getAddressesFailedText = "Failed to get addresses. Please try again later."
export const getProductsFailedText = "Failed to get products. Please try again later."
export const getCategoriesFailedText = "Failed to get categories. Please try again later."
export const getVendorsFailedText = "Failed to get vendors. Please try again later."
export const getVendorFailedText = "Failed to get vendor. Please try again later."
export const loginToAddToCartText = 'Please login to add item to cart'
export const productRequestMaxLimitReachedText = 'You cannot add more than 500 characters'
export const NoAddressSelectedText = "Please select an address to place order"
export const NoAddressAddedText = "Please add an address to continue"
export const failedToSendAddressToDpanda = "Some error might have occurred, Please contact support if you keep facing the issue."
export const invalidCountryCodeOrPhoneNumberText = "Please enter a valid country code and mobile number";
export const noNetworkErrorText = "Please check your internet connection";


// function to return the errorDescription for a given errorCode
export let errorDescription = (errorCode) => {

    let errorDescriptionMap = {
        invalidOTP: "Invalid OTP",
        invalidPhoneNumber: "Invalid Phone Number",
        invalidCountryCode: "Invalid Country Code",
        invalidVerificationCode: "Invalid Verification Code",
        invalidUser: "Invalid User",
        invalidToken: "Invalid Token",
        tokenExpired: "Token Expired",
        invalidRequest: "Invalid Request",
        invalidRequestBody: "Invalid Request Body",
        invalidRequestHeader: "Invalid Request Header",
        invalidRequestQuery: "Invalid Request Query",
        invalidRequestPath: "Invalid Request Path",
        invalidRequestMethod: "Invalid Request Method",
        unexpectedError: "An Unexpected Error Occurred",
        productNotFound: "Product Not Found",
        invalidProduct: "Invalid Product",
        invalidPincode: "Invalid Pincode",
        invalidState: "Invalid State",
        invalidCity: "Invalid City",
        invalidAddress: "Invalid Address",
        invalidLandmark: "Invalid Landmark",
        invalidAddressName: "Invalid Address Name",
        invalidAlternatePhoneNumber: "Invalid Alternate Phone Number",
        invalidName: "Invalid Name",
        limitReached: "Max Limit Reached. Please Try Again Later",
        truecallerLoginFailed: "Truecaller Login Failed. Please Login Via Phone Number",
        outOfStock: "The product is out of stock",
    }
    let description = errorDescriptionMap[errorCode];
    return description ? description : "Oops!! Something went wrong. Please try again later.";
}

export let getLimitReachedErrorMessage = (time) => {
    if (parseInt(time?.hours) > 0 && parseInt(time?.minutes)) {
        return `Max OTP Limit reached. Please try again in ${time?.hours} Hours & ${time?.minutes} minutes`
    } else if (parseInt(time?.hours) > 0) {
        return `Max OTP Limit reached. Please try again in ${time?.hours} Hours`
    } else if (parseInt(time?.minutes) > 0) {
        return `Max OTP Limit reached. Please try again in ${time?.minutes} minutes`
    } else {
        return `Max OTP Limit reached. Please try again in ${time?.seconds} seconds`
    }
}

// error codes
export const limitReachedErrorCode = "limitReached"
export const invalidVerificationErrorCode = "invalidVerificationCode"
export const maxOTPLimitReachedErrorCode = "limitReached"
export const invalidName = "invalidName";
export const truecallerLoginFailed = "truecallerLoginFailed";
export const outOfStock = "outOfStock";
export const noNetworkErrorCode = "ERR_NETWORK";