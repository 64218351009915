import { createSlice } from '@reduxjs/toolkit';

const globalSlice = createSlice({
    name: 'global',
    initialState: {
        isProductSharingActive: false,
        sharedProductId: null,
        focusOnSearchBar: false,
        truecallerLoginOngoing: false,
        truecallerLoginSuccess: false,
        getUserLocationFromClientOnGoing: false,
        encryptedUserMetadataData: null,
        encryptedRegisterTasksData: null,
        encryptedRedeemRewardsData: null,
        refreshRewardsHomeData: null,
        refreshRewardsHomeDataInternal: null,
    },
    reducers: {
        setIsProductSharingActive: (state, action) => {
            state.isProductSharingActive = action.payload
        },
        setSharedProductId: (state, action) => {
            state.sharedProductId = action.payload
        },
        setProductSharingState: (state, action) => {
            state.isProductSharingActive = action.payload.isProductSharingActive
            state.sharedProductId = action.payload.sharedProductId
        },
        setFocusOnSearchBar: (state, action) => {
            state.focusOnSearchBar = action.payload
        },
        setTrueCallerLoginOngoing: (state, action) => {
            state.truecallerLoginOngoing = action.payload
        },
        setTrueCallerLoginSuccess: (state, action) => {
            state.truecallerLoginSuccess = action.payload
        },
        setGetUserLocationFromClientOnGoing: (state, action) => {
            state.getUserLocationFromClientOnGoing = action.payload;
        },
        setEncryptedUserMetadataData: (state, action) => {
            console.log("setEncryptedUserMetadataData", action.payload);
            state.encryptedUserMetadataData = action.payload;
        },
        setEncryptedRegisterTasksData: (state, action) => {
            state.encryptedRegisterTasksData = action.payload;
        },
        setEncryptedRedeemRewardsData: (state, action) => {
            state.encryptedRedeemRewardsData = action.payload;
        },
        setRefreshRewardsHomeData: (state, action) => {
            state.refreshRewardsHomeData = action.payload;
        },
        setRefreshRewardsHomeDataInternal: (state, action) => {
            state.refreshRewardsHomeDataInternal = action.payload;
        }
    }
});

export const {
    setIsProductSharingActive, setSharedProductId, setProductSharingState, setFocusOnSearchBar, setInstalledApps,
    setTrueCallerLoginOngoing, setTrueCallerLoginSuccess, setGetUserLocationFromClientOnGoing, setEncryptedUserMetadataData,
    setEncryptedRedeemRewardsData, setEncryptedRegisterTasksData, setRefreshRewardsHomeData, setRefreshRewardsHomeDataInternal
} = globalSlice.actions;

export default globalSlice.reducer;
