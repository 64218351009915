
import Axios from "axios";
import fileDownload from 'js-file-download';
import { Other, defaultCountryCode } from '../../config';
import { countryCodes } from "../../config/country-codes";
import Router from 'next/router';
import { regions } from "../../config/regions";
import { showToast, toastTypes } from "./toasts";
import { SUPER_APPS_API_HOST } from "../../config";
import { store } from "../../store/store";
import { addToTrendingSearchKeyword } from "../../store/slices/user";
import { getTrendingSearchList } from "../apiUtils/product";
import { noNetworkErrorCode, noNetworkErrorText, unexpectedErrorText } from "../errors";

const validatePhoneNumber = (phoneNumber) => {
    let reg = new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$");
    return phoneNumber.length === 10 && reg.test(phoneNumber);
}

const validateCountryCode = (countryCode) => {

    for (const element of countryCodes) {
        if (countryCode == element.label) {
            return true;
        }
    }
    return false
}

const capitalizeFirstLetter = (string) => {
    if (!string || string.length === 0) return
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const isInViewPort = (element) => {
    // Get the bounding client rectangle position in the viewport   

    if (element) {
        let distance = element.offsetTop + element.offsetHeight;
        // console.log("distance >= window.scrollY", distance, window.scrollY, distance <= window.scrollY);
        return distance <= window.scrollY;
    }
    return false
};

const isInViewPortHomePage = (element) => {
    // Get the bounding client rectangle position in the viewport   

    if (element) {
        // enable the search bar and categories on 2 mouse scrolls
        return 55 <= window.scrollY;
    }
    return false
};

// scrollToTop 
const scrollToTop = () => {

    if (window) {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
}

const validateState = (state) => {
    let states = regions;
    let isValid = false;
    if (states) {
        states.map((s) => {
            if ((s.name.toString().toLowerCase() == state.toString().toLowerCase()) ||
                (s.iso_code.toString().toLowerCase() === state.toString().toLowerCase())
            ) {
                isValid = true;
            }
        })
    }
    return isValid;
}

// validates user address
const validateUserAddress = async (address) => {
    console.log("validateUserAddress::: ", address);
    let regPhoneNumber = new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$");
    let regPincode = new RegExp("^[1-9]{1}[0-9]{2}[0-9]{3}$");
    if (address.name.length === 0) {
        console.error("Please enter name");
        return "invalidName";
    } else if (address.phoneNumber.length !== 10 || !regPhoneNumber.test(address.phoneNumber)) {
        console.error("Please enter valid mobile number");
        return "invalidPhoneNumber";
    } else if (address.pincode.length !== 6 || !regPincode.test(address.pincode)) {
        console.error("Please enter valid pincode");
        return "invalidPincode";
    } else if (address.state.length === 0 || !validateState(address.state)) {
        console.error("Please enter valid state");
        return "invalidState";
    }
    else if (address.city.length === 0) {
        console.error("Please enter city");
        return "invalidCity";
    }
    else if (address.address.length === 0) {
        console.error("Please enter address");
        return "invalidAddress";
    }
    // else if (address.landmark.length === 0) {
    //     console.error("Please enter landmark");
    //     return "invalidLandmark";
    // } 
    // eslint-disable-next-line no-undef
    else if (address.addressType === Other && addressName.length === 0) {
        console.error("Please enter address name");
        return "invalidAddressName";
    } else if (address.addAlternatePhoneNumber && address.alternatePhoneNumber.length !== 10) {
        console.error("Please enter valid alternate mobile number");
        return "invalidAlternatePhoneNumber";
    } else if (!address.type || address?.type?.length === 0) {
        console.error("Please select address type");
        return "invalidAddressType";
    }

}

export const validateUserLocation = (address) => {
    if (address?.pincode?.length !== 6) {
        console.error("Please enter valid pincode");
        return "invalidPincode";
    } else if (address?.state?.length === 0 || !validateState(address.state)) {
        console.error("Please enter state");
        return "invalidState";
    }
    else if (address?.city?.length === 0) {
        console.error("Please enter city");
        return "invalidCity";
    }
    else if (address?.address?.length === 0) {
        console.error("Please enter address");
        return "invalidAddress";
    }
    // else if (address?.landmark?.length === 0) {
    //     console.error("Please enter landmark");
    //     return "invalidLandmark";
    // }
    // eslint-disable-next-line no-undef
    else if (address?.addressType === Other && addressName?.length === 0) {
        console.error("Please enter address name");
        return "invalidAddressName";
    }
}

const parseClientData = (clientData) => {
    let data = []
    let parsedData = {};

    if (clientData && clientData.length > 0) {
        data = clientData.split(",");
        if (data.length > 0) {
            for (const element of data) {
                let keyValue = element.split("=");
                if (keyValue.length > 1) {
                    let key = keyValue[0].trim();
                    let value = keyValue[1].trim();
                    parsedData[key] = value
                }
            }
        }
    }

    return parsedData;
}

const validateAccessToken = (accessToken, expiresIn) => {
    console.log("validateAccessToken::: ", accessToken, expiresIn);
    if (accessToken && accessToken.length > 0) {
        let currentTime = new Date().getTime();
        let expiresAt = expiresIn * 1000 + currentTime;
        console.log("validateAccessToken currenTime expiresAt::: ", currentTime, expiresAt);
        console.log("validateAccessToken::: ", currentTime < expiresAt);
        return expiresAt > currentTime;
    }
    console.log("validateAccessToken::: ", false);
    return false;
}


export const downloadFile = (url) => {
    console.log("downloadFile::: ", url);
    Axios.get(url, {
        responseType: 'blob',
    }).then(res => {
        fileDownload(res.data, url?.split("/")?.[url?.split("/")?.length - 1]);
    });
}

export const parseVariants = (product) => {
    let _variants = [];
    for (let variant of product?.variants) {
        let _images = [];
        let _variant = {};
        if (product?.data?.images?.length > 0) {
            for (let image of product?.data?.images) {
                if (image.variantIds?.length == 0 || image.variantIds?.includes(variant.sourceVariantId)) {
                    _images.push(image);
                }
            }
        }

        _variant = { ...variant, images: _images };
        _variants.push(_variant);
    }
    return _variants;
}

export const getCountryCode = (countryCodeNumeric) => {
    let numericCode = countryCodeNumeric?.toString();

    if (numericCode?.[0] != "+") {
        numericCode = "+" + numericCode;
    }

    for (const element of countryCodes) {
        if (element.label === numericCode) {
            return element.code;
        }
    }
    return null;
}


export const getCountryLabelFromCode = (countryCode) => {
    for (const element of countryCodes) {
        if (element?.code?.toLowerCase() === countryCode?.toLowerCase()) {
            return getCountryTelephoneCode(element.label);
        }
    }
    return null;
}

export const getCountryTelephoneCode = (countryCode) => {
    if (!countryCode || countryCode === "") return defaultCountryCode;
    return countryCode?.[0] == "+" ? countryCode.slice(1) : countryCode;
}

export const parsePrices = (price, decimalReq, isCurrency) => {
    if (price) {
        if (isCurrency == true && decimalReq == true) {
            return price.toFixed(2).replace(/(\d)(?=(\d{2})+\d\.)/g, '$1,')
        }
        else if (decimalReq == false) {
            return price.toFixed(0).replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")
        }
        else if (isCurrency == false) {
            return parseFloat(price).toFixed(2)
        }
    }

    return price
}

export const toggleCSSForValidDiscount = (discount) => {
    let parsedDiscount = parsePrices(discount, false, false) ?? 0;
    return parsedDiscount != 0 ? "" : "display-none";
}

export const toggleCSSForValidDiscountTag = (discount) => {
    let parsedDiscount = parsePrices(discount, false, false) ?? 0;
    return parsedDiscount != 0 ? "" : "visibility-hidden";
}

export const goBack = () => {
    console.log({ history: window.history, Router });
    if (window?.history?.length == 1) {
        Router.replace("/bobblexclusive/home")
    } else {
        Router.back()
    }
}

export const convertTimeToHours = (seconds) => {
    let time = {
        hours: 0,
        minutes: 0,
        seconds: 0
    }

    time.hours = Math.floor(seconds / 60 / 60);
    time.minutes = Math.floor(seconds / 60) - (time?.hours * 60);
    time.seconds = seconds % 60;

    return time;
}

export const stopBrowserBack = callback => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
        callback();
    };
};

export const startBrowserBack = () => {
    window.onpopstate = undefined;
    window.history.back();
};

export const getApplyPromoCodeURLV2 = (url) => {
    let _url = url;
    let basePath = SUPER_APPS_API_HOST + "/v1"
    let newBasePath = SUPER_APPS_API_HOST + "/v2"
    if (url?.includes(basePath)) {
        _url = url?.replace(basePath, newBasePath);
    }
    return _url;
}

// gives epoch time in milli seconds
export const convertUTCToEPOCHTime = (date) => {
    let _date = new Date(date);
    return _date?.getTime();
}

// validates if the dpana token is valid or not valiDateDpandaAuthToken
export const valiDateDpandaAuthToken = (token, expiresAt) => {
    console.log("valiDateDpandaAuthToken::: ", token, expiresAt);
    let currentTime = new Date().getTime();
    console.log("valiDateDpandaAuthToken::: ", currentTime, expiresAt);
    // showToast(`valiDateDpandaAuthToken::: ${currentTime} ${expiresAt}`, toastTypes.info);
    return expiresAt > currentTime;
}

// return "" if string is null, undefined or "null" else will return string
export const returnValidStringFromRawInput = (value) => {
    if (!value) return "";
    else if (value.toLowerCase() === "null") return "";
    else return value;
}

export const getTrendingSearch = async () => {
    try {
        const { dispatch } = store;
        let response = await getTrendingSearchList();
        dispatch(addToTrendingSearchKeyword(response?.data?.keywords));
    } catch (error) {
        error?.code === noNetworkErrorCode ? showToast(noNetworkErrorText, toastTypes.error) :
            showToast(unexpectedErrorText, toastTypes.error);
    }
}

export const getValidObjectKeyFromString = (string = "") => string.replace(/[^\w\s]/gi, '').replace(/ /g, '_').replace(/_+/g, '_')

export const activeCategoryHighlighter = () => {
    // Get all sections that have an ID defined
    const sections = document.querySelectorAll("section[id]");

    // Get current scroll position
    let scrollY = window.pageYOffset;

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach(current => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = current.offsetTop - 230;
        const sectionId = current.getAttribute("id");

        /*
        - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
        - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
        */
        if (
            scrollY > sectionTop &&
            scrollY <= sectionTop + sectionHeight
        ) {
            document.getElementById(`${sectionId}-navLink`)?.classList.add("active");
            document.getElementById(`${sectionId}-navLink`)?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            })
        } else {
            document.getElementById(`${sectionId}-navLink`)?.classList.remove("active");
        }
    });
}

export {
    validatePhoneNumber,
    validateCountryCode,
    validateUserAddress,
    capitalizeFirstLetter,
    isInViewPort,
    isInViewPortHomePage,
    scrollToTop,
    parseClientData,
    validateAccessToken,
};

